import axios from 'axios'

const API = '/manager/leecheros'

const Leecheros = {
  listar(params){
    return axios.get(`${API}`,{params})
  },
  obtenerLeechero(params){
    return axios.get(`${API}/obtener-leechero`,{params})
  },
  guardar(payload){
    return axios.post(`${API}`,payload)
  },
  eliminarLeechero(params){
    return axios.delete(`${API}/eliminar-leechero`,{params})
  }

}

export default Leecheros
