<template>
    <section class="px-5">
        <div class="row mx-0">
            <div class="col-7 px-0">
                <p class="text-black f-20 f-600">
                    Términos y condiciones
                </p>
            </div>
            <div class="btn-general f-14 px-3" @click="$refs.modalNuevoTermino.toggle()">
                Crear término
            </div>
            <div class="col-8 px-0 mt-2 mb-4">
                <p class="text-black f-15 mt-1">
                    Crea los términos legales que considere de la naturaleza de este sitio web, esto con el objetivo de protegerse como propietario de una posible exposición legal.
                </p>
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-4" style="height:calc(100vh - 280px);">
            <draggable :list="preguntas" handle=".handle" @change="cambiarPosicion">
                <div v-for="(pre, index) in preguntas" :key="index" class="list-question mb-4">
                    <div class="row mx-0 align-items-center">
                        <i class="icon-dragable handle mr-2 f-15 text-muted" />
                        <div class="col-8 titulo-pregunta pr-2 d-middle">
                            <div class="col f-17 f-14 px-0 text-black">
                                <p class="input-pregunta"> {{ pre.titulo }} </p>
                            </div>
                            <template>
                                <i class="icon-editar cr-pointer f-17 text-black ml-auto" @click="modalEditarTermino(pre)" />
                                <i class="icon-eliminar f-17 text-black ml-2 cr-pointer" @click="modalEliminarTermino(pre.id)" />
                                <i class="text-black f-25 cr-pointer" :class="pre.show ? 'icon-chevron' : 'icon-chevron-down'" @click="mostrar(pre)"/>
                            </template>
                        </div>
                    </div>
                    <template v-if="pre.show">
                        <div class="col-8 text-black mt-2 f-15 px-0 pl-4" style="white-space:pre-line;">
                            {{ _.get(pre.texto,'texto','Sin información.') }}
                        </div>
                    </template>
                </div>

            </draggable>
        </div>
        <!-- Partials -->
        <modal-nuevo-termino ref="modalNuevoTermino" @actualizar="getTerminos"/>
        <modal-editar-termino ref="modalEditarTermino" @actualizar="getTerminos"/>
        <modal-eliminar ref="modalEliminarTermino" titulo="Eliminar término" mensaje="¿Desea eliminar este término?" @eliminar="eliminarTermino(idFaqToDelete)"/>
    </section>
</template>

<script>
import Configuracion from '~/services/configuracion'
export default {
    components: {
        modalNuevoTermino: () => import('./partials/modalNuevoTermino'),
        modalEditarTermino: () => import('./partials/modalEditarTermino')
    },
    data(){
        return {
            idFaqToDelete: null,
            preguntas: [],
        }   
    },
    mounted(){
        this.getTerminos()
    },
    methods: {
        cambiarPosicion(event){
            let nuevaPosicion = event.moved.newIndex + 1
            let viejaPosicion = event.moved.oldIndex + 1
            let params = {
                nueva: nuevaPosicion,
                vieja: viejaPosicion,
                id_faq: event.moved.element.id
            }

            this.cambiarPosicionPregunta(params)
        },
        mostrar(pre){
            pre.show = !pre.show
        },
        modalEditarTermino(faq){
            let terminoCloned = _.clone(faq)
            let termino = {
                id: terminoCloned.id,
                titulo : terminoCloned.titulo,
                respuesta: _.get(terminoCloned.texto,'texto','')
            }
            this.$refs.modalEditarTermino.toggle(termino)
        },
        modalEliminarTermino(idGrupo){
            this.idFaqToDelete = idGrupo
            this.$refs.modalEliminarTermino.toggle();
        },
        async getTerminos(){
            try {

                let tipo = 2

                const { data } = await Configuracion.getFaqs({tipo})
                this.preguntas = data.data.faqs.map(el=>{
                    el.edit = false
                    el.show = false
                    return el})
            } catch (error) {
                this.error_catch(error)
            }
        },
        async cambiarPosicionPregunta(params){
            try {

                const {data} = await Configuracion.cambiarPosicion(params)

                this.notificacion('','Posición cambiada correctamente','success')
            } catch (error) {
                this.error_catch(error)
            }
        },
        async eliminarTermino(idPregunta = this.idFaqToDelete){
            try {
                const { data } = await Configuracion.eliminarFaq(idPregunta)

                this.notificacion('','Término eliminado correctamente','success')
                this.$refs.modalEliminarTermino.toggle();
                this.getTerminos()
            } catch (error) {
                this.error_catch(error)
            }
        }
        
    }
}
</script>
<style lang="scss" scoped>
/* .list-question{

} */
.titulo-pregunta{
    background-color: rgba(64, 158, 255, 0.141);
    height: 40px;
    border-radius: 4px;
    &-edit{
        height: 40px;
        border-radius: 4px;
        background-color: #FFFFFF;
        border: 1px solid #dbdbdb;
    }
}
.childs-title{
    height: 40px;
    border-radius: 4px;
}
</style>