import axios from 'axios'

const API = '/manager/vigilante'

const Conjuntos = {
    getListarVigilantes(params){
      return axios.get(`${API}/listar-vigilantes`,{params})
    },
    getDetalleVigilante(params){
      return axios.get(`${API}/detalle-vigilante`,{params})
    },
    guardarVigilante(payload){
      return axios.post(`${API}/guardar-vigilante`,payload)
    },
    editarVigilante(idVigilante,payload){
      return axios.put(`${API}/editar-vigilante/${idVigilante}`,payload)
    },
    eliminarVigilante(idVigilante, params){
      return axios.delete(`${API}/eliminar-vigilante/${idVigilante}`,{params})
    }
}

export default Conjuntos
