<template>
    <section>
        <div class="row mx-0 align-items-center">
            <div class="col-auto px-4" />
            <div class="col-auto px-0 f-500 text-black f-20 pt-4"> Tiendas y proveedores </div>
            <button type="button" class="btn-general mt-4 mr-3 ml-auto" @click="redireccion">
                Crear Tienda
            </button>
            <el-popover placement="bottom" trigger="hover" :visible-arrow="false">
                <div class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4" style="height:25px;" @click="openEditCategory">
                    Editar categoria
                </div>
                <div class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4" style="height:25px;" @click="openDeleteCategory">
                    Eliminar categoria
                </div>
                <div slot="reference" class="col-auto pl-0">
                    <div  class="br-4 border btn-action cr-pointer mt-4">
                        <i class="icon-opciones f-15 text-black" />
                    </div>
                </div>
            </el-popover>
        </div>
        <div class="row mx-0 mb-3">
            <div class="col-auto ml-auto pr-0 pt-2">
                <p class="text-general f-12 pl-3">Intenciones de compra durante</p>
                <el-select v-model="rango"  size="small">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div class="col-3 pr-0 pt-2">
              <p class="text-general f-12 pl-3">Intenciones de compra durante</p>
                <el-date-picker
                v-model="fecha"
                class="w-100"
                type="daterange"
                size="small"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="Fecha Inicio"
                end-placeholder="Fecha Fin"
                :disabled="disable_fecha"
                @change="getListaTiendas"/>
            </div>
            <div class="col-auto pt-4">
                <el-input v-model="buscar"  size="small" placeholder="Buscar" @input="busqueda"/>
            </div>
        </div>
        <div v-if=" filterData.length < 1" class="d-middle-center">
            <div class="text-center" style="min-width:180px;">
                <div class="bg-whitesmoke p-3 br-5">
                    <img height="100" src="/img/no-imagen/empty_residentes.svg" alt="" />
                 </div>
                <p> No se han encontrado registros</p>
            </div>
        </div>
        <div v-else >
        <div class="row mx-0 py-2 border-b-whitesmoke">
            <div class="col-4 text-center f-16 text-general f-500">
                Tienda
            </div>
            <div class="col text-left f-16 text-general f-500">
                Número de teléfono
            </div>
            <div class="col text-left f-16 text-general f-500">
                Conjuntos
            </div>
            <div class="col text-left f-16 text-general f-500">
                Intenciones de compra
            </div>
        </div>
        <draggable tag="div" :list="filterData" class="custom-scroll overflow-auto" handle=".handle" style="height:calc(100vh - 246px)" @change="cambiarPosicion">
            <div v-for="(table, t) in filterData" :key="t" class="row mx-0 py-2 border-b-whitesmoke text-general cr-pointer" @click="irServicio(table.id)">
                <div class="col-4 d-middle">
                    <i class="icon-dragable text-gris handle f-18" />
                    <img :src="table.logo" class="obj-cover br-4 mx-3" width="44" height="44" />
                    <span class="mx-3 f-16 f-300">
                        {{table.nombre}}
                    </span>
                </div>
                <div class="col d-middle">
                    {{ table.telefono }}
                </div>
                <div class="col d-middle">
                    {{table.cant_conjuntos}}
                </div>
                <div class="col d-middle">
                    {{table.cant_visitas}}
                </div>
            </div>
        </draggable>
        </div>
        <!-- partials -->
        <modalCreateCategory ref="openModalCreateCategory" />
        <modal-eliminar ref="refModalDeleteCategory" titulo="Eliminar categoría" mensaje="¿Desea eliminar esta tienda?" subMensaje="Se eliminarán todas las tiendas de esta categoría" @eliminar="handleDeleteCategory" />
    </section>
</template>
<script>
import Servicios from '~/services/tiendas'
import moment from 'moment'
export default {
    components: {
        modalCreateCategory: () => import('./partials/modalCreateCategory'),
    },
    data(){
        return {
            listaServicios: [],
            servicesBusqueda: [],
            buscar: "",
            filterData: [],
            rango: 2,
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            disable_fecha: true,
            options: [{
                value: 1,
                label: 'Hoy'
            }, {
                value: 2,
                label: 'Ultima semana'
            }, {
                value: 3,
                label: 'Ultimo mes'
            }, {
                value: 4,
                label: 'Ultimo 3 meses'
            }, {
                value: 5,
                label: 'Personalizado'
            }],
        }
    },
    computed: {
        idCategoria() {
            return Number(this.$route.params.idCategoria)
      }
    },
    watch: {        
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.getListaTiendas()
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.getListaTiendas()
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.getListaTiendas()
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(3, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.getListaTiendas()
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                this.getListaTiendas()
                break;
            default:
                break;
            }
        },
        idCategoria(val){
            this.getListaTiendas();
        }
    },
    mounted(){
      this.getListaTiendas();
    },
    methods: {
        openEditCategory(){
            const tipo = 'editar';
            const idCategoria = this.$route.params.idCategoria;
            this.$refs.openModalCreateCategory.toggle(tipo, idCategoria)
        },
        openDeleteCategory(){
            this.$refs.refModalDeleteCategory.toggle()
        },
        async handleDeleteCategory(){
            try {
                const { data } = await Servicios.deleteCategoria({idCategoria: this.idCategoria})
                if (data.success) {
                    this.notificacion('Éxito', data.mensaje, "success")                    
                    this.$refs.refModalDeleteCategory.toggle()
                    this.$router.push({name: "tiendas.main"});
                }
                this.$emit('actualizar');
            } catch (error) {
                this.error_catch(error)
            }
        },
        redireccion(){
            this.$router.push({name: "tiendas-proveedores.crear.parte-uno", params: { idCategoria: this.$route.params.idCategoria }});
        },
        irServicio(item){
            this.$router.push({name: "tiendas-proveedores.crear", params: { idServicio: item }});
        },
        cambiarPosicion(event){
            let nuevaPosicion = event.moved.newIndex + 1
            let viejaPosicion = event.moved.oldIndex + 1
            let params = {
                nuevaPosicion: nuevaPosicion,
                antiguaPosicion: viejaPosicion,
                idCategoria: this.$route.params.idCategoria,
            }
            this.cambiarPosicionServicio(params)
        },
        async getListaTiendas(){
          try{
            let params = {
                fechaInicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fechaFin: moment(this.fecha[1]).format('Y-MM-DD'),
                idCategoria: this.$route.params.idCategoria
             }
              const { data } = await Servicios.getListAllServices(params)
              this.listaServicios = data.serviciosDisponibles
              this.filterData = data.serviciosDisponibles
          } catch (error) {
                this.error_catch(error)
          }
        },
        async cambiarPosicionServicio(params){
            try {
                const {data} = await Servicios.actualizarPosicionServicios(params)
                this.notificacion('', data.mensaje, 'success')
            } catch (error) {
                this.error_catch(error)
            }
        },
        busqueda(){
        this.filterData = []
        this.filterData = this.listaServicios.filter(data => !this.buscar || data.nombre.toLowerCase().includes(this.buscar.toLowerCase()));
        }
    }
}
</script>