<template>
    <section>
        Estadisticas del conjunto
    </section>
</template>

<script>
export default {
    data(){
			return {
					
			}
    },
}
</script>