<template>
	<modal ref="modalEditarCategoria" titulo="Editar categoría" :btns="buttons" no-cancelar no-aceptar width="30%">
		<div class="mx-4">
			<ValidationObserver ref="form">
				<div class="mb-3">
					<label for="nombre" class="pl-3" >Nombre de la categoría</label>
					<ValidationProvider v-slot="{ errors }" name="categoría" rules="required|max:50">
						<el-input v-model="model.nombre" id="nombre" placeholder="Categoríá"/>
						<p class="text-danger f-10">{{ errors[0] }}</p>
					</ValidationProvider>
				</div>
				<div class="mb-4">
					<p for="image" class="text-center">Imagen</p>
					<div class="position-relative">
						<slim-cropper 
						ref="logo" 
						:options="slimOptions" 
						class="custom-cropper cr-pointer mx-auto"
						/>
						<div v-if="showCropperContent" class="cropper-content d-middle-center flex-column position-absolute">
							<div class="img mb-2"/>
							<span class="text">Arrastra una imagen</span>
							<span class="text">o</span>
							<span class="text">De clic aquí</span>
						</div>
					</div>
					<p v-if="imgErrors" class="text-center text-danger f-10" >{{ imgErrors }}</p>
				</div>
			</ValidationObserver>
		</div>
	</modal>
</template>

<script>
import Configuracion from '~/services/configuracion';

export default {
	data() {
		return {
			model: {
				id: null,
				nombre: '',
				logo: '',
			},
			img: null,
			buttons: [
				{
					text: 'Cerrar',
					className: 'bg-gris2 border text-muted f-14 px-4',
					action: this.cancelar,
				},
				{
					text: 'Guardar',
					className: 'bg-general text-white f-14 px-4',
					action: this.editarCategoria
				}
			],
			imgErrors: null,
			showCropperContent: true,
			slimOptions: {
                ratio: '1:1',
                label:'',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
				didLoad: (e) => {
					this.showCropperContent = false
					this.imgErrors = null;
					this.model.logo = e
					return true
				},
				didConfirm: (e) => {
					this.$nextTick(() => {
						this.img = null;
						e.output.image.toBlob(blob => {
							this.model.logo = new File([blob], 'logo.png', {type:'image/png'});
						});
					})
				},
				didRemove: (e) => {
					this.model.logo = ''
					this.showCropperContent = true
					this.$nextTick(() => {
						this.imgErrors = 'Debe seleccionar una imagen'
					})
				}
            },
		}
	},
	methods: {
		toggle(categoria){
			this.model.id = categoria.id
			this.model.nombre = categoria.nombre;
			if(categoria.logo){
				this.img = categoria.logo;
				this.showCropperContent = true
				this.$refs.logo.set_image(categoria.logo);
			}else{
				this.model.logo = '';
				this.$refs.logo.remove();
				setTimeout(() => {
					this.imgErrors = null
				},400)
			}
            this.$refs.modalEditarCategoria.toggle();
        },
		limpiar(){
			this.imgErrors = null;
		},
		cancelar(){
            this.$refs.modalEditarCategoria.toggle();
		},
		async editarCategoria(){
			try {
				const valid = await this.$refs.form.validate()
				if(this.model.logo == '') this.imgErrors = 'Debe seleccionar una imagen'
				if(!valid || this.imgErrors) return;

				const formData = new FormData();
				formData.append('nombre', this.model.nombre);
				
				if(!this.img) formData.append('logo', this.model.logo)

				const { data } = await Configuracion.editarCategoriasTiendas(this.model.id, formData);

                this.notificacion('','Categoría actualizada correctamente','success')
            	this.$refs.modalEditarCategoria.toggle();

                this.$emit('actualizado', data.data)
			} catch (error) {
                this.error_catch(error)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.cropper-content{
	pointer-events: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	.img{
		width: 97px;
		height: 97px;
		background-image: url('/img/estados-vacios/anadir_imagen.svg');
		background-size: contain;
	}
	.text{
		color: var(--text-general);
		font-size: 11px;
	}
}
</style>