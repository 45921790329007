<template>
    <section>
        <div class="row mx-0 px-5">
            <div style="width:735px;">
                <div class="row mx-0">
                    <div class="col-auto px-0 text-black f-600 f-20">
                        Tipo de anuncios   
                    </div>
                    <div class="col-auto px-0 ml-auto">
                        <router-link :to="{name: 'configurar.manager.crear-tipo-anuncio'}">
                            <div class="bg-general text-white br-4 d-middle-center px-4 cr-pointer shadow" style="height:32px;">
                                Crear
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 px-0 text-black f-15 mt-2">
                        Los tipos de anuncios serán sugeridos para los administradores, ellos podrán modificarlos o crear nuevos.
                    </div>
                </div>
                <div class="row mx-0 py-4" />
                <div v-for="(tipo, t) in tipos" :key="t" class="row mx-0 align-items-center mb-2">
                    <div :class="`rounded-circle border d-middle-center text-general `" :style="`width:40px;height:40px; background-color:${tipo.color};`">
                        <i :class="tipo.icono" />
                    </div>
                    <p class="col-5 text-black f-15">
                        {{ tipo.nombre }}
                    </p>
                    <div class="col-2">
                        <div class="pill">
                            <i class="icon-fecha_calendario_hoy f-14" />
                            <span class="f-12 mx-1">{{ tipo.vigencia }} Días</span>
                        </div>
                    </div>
                    
                    <div class="col-2 ml-3">
                        <div v-show="tipo.dinero" class="pill">
                            <span class="f-12 mx-1">Tipo valor</span>
                        </div>
                    </div>
                    <div class="col-auto pr-0">
                        <el-popover
                        placement="bottom-start"
                        trigger="hover">
                            <div class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4" style="height:25px;" @click="eliminarAnuncio(tipo.id)">
                                Eliminar
                            </div>
                            <router-link :to="{name: 'configurar.manager.editar-tipo-anuncio',params: { id: tipo.id, editar_anuncio: true }}">                              
                              <div class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4" style="height:25px;">
                                Editar
                              </div>
                            </router-link>

                            <div slot="reference" class="br-4 border btn-action">
                                <i class="icon-opciones f-15 text-black" />
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminar" titulo="Eliminar tipo de anuncio" mensaje="¿Desea eliminar este tipo de anuncio?" @eliminar="eliminar"/>
    </section>
</template>

<script>
import Configuracion from '~/services/configuracion'

export default {
    data(){
        return {
          tipos : [],
          idEliminar:null
        }
    },
    mounted(){
      this.listar()
    },
    methods: {      
      eliminarAnuncio(idEliminar){
        this.idEliminar = idEliminar
        this.$refs.modalEliminar.toggle();
      },
      async listar(){
        try{
          const { data } = await Configuracion.listarTiposAnuncios()
          this.tipos = data.data
        }catch(e){

        }				
      },
      async eliminar(){
				try{
					this.loading = true
					const params = {
						id_tipo : this.idEliminar
					}
					const { data } = await Configuracion.eliminarTipoAnuncio(params)
					this.listar()
          if(data.success){
						this.$notify({
							title: 'Eliminar',
							message: data.mensaje,
							type: 'success'
						});
					}
          this.$refs.modalEliminar.toggle();
					this.loading = false
				}catch(e){
          this.$refs.modalEliminar.toggle();
          if(data.success){
						this.$notify({
							title: 'Eliminar',
							message: data.mensaje,
							type: 'warning'
						});
					}
					this.loading = false
				}		
        },
    }
}
</script>
<style lang="scss" scoped>
.pill{
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 71px;
    border-radius: 20px !important;
    color: #000000;
    background-color: #F5F5F5;
}
a{
    text-decoration: none !important;
}
.bg-yellow{
    background-color: #FFD54F;
}
.bg-purple{
    background-color: #B2B2FF;
}
.bg-green{
    background-color: #93EDCF;
}
.bg-pink{
    background-color: #FFB2C2;
}
.bg-blue{
    background-color: #80DCFF;
}
.bg-red{
    background-color: #FF9680;
}
.item:hover{
    background-color:#EBECF0a8;
}
</style>