import axios from 'axios'

const API = 'estadisticas'

const ENDPOINTS = {
    getConjuntos(){
        return axios.get(`${API}/conjuntos/listar`)
    },
    getGraficas(params){
        return axios.get(`${API}/conjuntos/detalle`, {params})
    },
    getOtrasMascotas(params){
        return axios.get(`${API}/conjuntos/mascotas/otros`, {params})
    }
};

export default ENDPOINTS