<template>
    <section class="px-5 pt-3">
        <div class="row mx-0 mb-4">
            <div class="col-auto px-0 text-black f-500 f-20">
                Vigilantes
            </div>
            <div class="col-3 ml-auto">
                <el-input v-model="buscar" prefix-icon="icon-buscar f-16" size="small" placeholder="Buscar" class="w-100" @input="getVigilantes" />
            </div>
            <div class="btn-general px-3" @click="crearVigilante">
                Crear vigilante
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 205px)">
            <div class="row mx-0">
                <div v-for="(data,key) in vigilantes" :key="key" class="card-vigilante mr-4 cr-pointer mb-3" @click="editarVigilante(data.id)">
                    <div class="row mx-0 h-100 align-items-center">
                        <div class="col-12">
                            <i class="icon-usuario_escudo text-black f-18" />
                            <span class="text-black f-16 f-500"> {{ data.nombre }} {{data.apellido}} </span>
                        </div>
                        <div class="col-12 d-middle">
                            <i class="icon-telefono text-black f-18" />
                            <span class="text-black f-14"> {{ data.telefono }} </span>
                            <div v-if="data.responsable == 1" class="pr-0 f-13 text-primary ml-auto">
                                Responsable
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Vigilante from '~/services/vigilantes'
export default {
    data(){
        return {
            buscar: '',
            vigilantes: []
        }
    },
    mounted(){
      this.getVigilantes()
    },
    methods:{
        async getVigilantes(){
            try {
                const busqueda = async() => {
                    const params = {
                        idConjunto : this.$route.params.id,
                        buscar: this.buscar
                    }
                    const {data} = await Vigilante.getListarVigilantes(params)
                    this.vigilantes = data.data
                }
                await this.delay(busqueda)
            } catch (e){
                this.error_catch(e)
            }
        },
        crearVigilante(){
            this.$router.push({name: 'conjuntos.crear.vigilante'})
        },
        editarVigilante(id){
            this.$router.push({name: 'conjuntos.crear.vigilante-id', params: { idVigilante: id }})
        },
  }
}
</script>
<style lang="scss" scoped>
.card-vigilante{
    width: 281px;
    height: 82px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    background-color: #FCFCFC;
    &:hover{
        box-shadow: 0px 3px 6px #00000029;
    }
}
</style>