<template>
    <section class="crear-vivienda custom-scroll overflow-auto" style="height:calc(100vh - 132px);">
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col d-flex">
                <div class="col">
                    <div v-if="!admin.id" class="row mx-0 mb-4">
                        <div class="col-auto pl-0 text-black f-20 f-600">
							Crear Administrador
                        </div>
                    </div>
                    <div v-else class="row mx-0 mb-4">
                        <div class="d-flex" style="width:390px;">
                            <div class="col-8 pl-0 text-black f-20 f-600">
                                Editar Administrador
                            </div>
                            <div class="col-auto ml-auto pr-0">
                                <el-popover
                                placement="bottom"
								popper-class="p-2"
                                trigger="click">
                                    <div class="row mx-0 item-option align-items-center px-2 cr-pointer br-4" @click="eliminarAdministrador">
                                        Eliminar
                                    </div>
                                    <div slot="reference" class="btn-action cr-pointer">
                                        <i class="icon-opciones f-15 text-black" />
                                    </div>
                                </el-popover>
                            </div>
                        </div>
                    </div>
					<ValidationObserver ref="form">
						<div class="row mx-0 mb-2">
							<div class="" style="width:390px;">
								<p class="text-general f-12 pl-3"> Nombre </p>
								<ValidationProvider v-slot="{errors}" rules="required|max:20" name="nombre">
									<el-input v-model="admin.nombre" size="small" show-word-limit maxlength="20" placeholder="Nombre" class="w-100" />
									<span class="text-danger f-10">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
						</div>
						<div class="row mx-0 mb-2">
							<div class="" style="width:390px;">
								<p class="text-general f-12 pl-3"> Apellido </p>
								<ValidationProvider v-slot="{errors}" rules="required|max:20" name="apellido">
									<el-input v-model="admin.apellido" size="small" show-word-limit maxlength="20" placeholder="Apellido" class="w-100" />
								<span class="text-danger f-10">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
						</div>
						<div class="row mx-0 mb-2">
							<div class="" style="width:390px;">
								<p class="text-general f-12 pl-3"> Correo electrónico </p>
								<ValidationProvider v-slot="{errors}" rules="email|max:70" name="correo">
									<el-input v-model="admin.correo" size="small" show-word-limit maxlength="70" placeholder="Correo" class="w-100" />
								<span class="text-danger f-10">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
						</div>
						<div class="row mx-0 mb-2">
							<div class="" style="width:390px;">
								<p class="text-general f-12 pl-3"> Número celular </p>
								<ValidationProvider v-slot="{errors}" rules="required|numeric|max:14" name="número">
									<el-input v-model="admin.telefono" size="small" show-word-limit maxlength="14" placeholder="Número" class="w-100" />
									<span class="text-danger f-10">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
						</div>
						<div class="row mx-0 mb-2">
							<div class="" style="width:390px;">
								<p class="text-general f-12 pl-3"> Contraseña </p>
								<ValidationProvider v-slot="{errors}" rules="required|min:6|max:15" name="contraseña">
									<el-input v-model="admin.contrasena" type="password" size="small" show-word-limit maxlength="15" placeholder="Contraseña"  class="w-100" />
								<span class="text-danger f-10">{{ errors[0] }}</span>
								</ValidationProvider>
								<p class="text-general f-12 pl-3"> Repetir contraseña </p>
								<ValidationProvider v-slot="{errors}" rules="confirmed:contraseña|required||min:6|max:15" name="repetir contraseña">
									<el-input v-model="repetir" type="password" size="small" show-word-limit maxlength="15" placeholder="Contraseña"  class="w-100" />
								<span class="text-danger f-10">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
						</div>
						<div class="row mx-0 my-3 d-middle">
							<el-checkbox v-model="admin.responsable" :true-label="1" :false-label="0" class="check-dark" />
							<div class="col-auto f-14">
								Administrador responsable
								<el-tooltip placement="bottom" content="Añadir pregunta" effect="light">
									<template #content>
										<p class="f-14 text-general">Número de contacto disponible que tendrá el vigilante para comunicarse con administración desde la app. Solo puede haber un administrador responsable.</p>
									</template>
									<i class="icon-interrogacion f-18 text-black" />
								</el-tooltip>
							</div>
						</div>
					</ValidationObserver>
                    <div class="row mx-0 mt-4 mb-2">
                        <div class="pl-0 d-flex" style="width:390px;">
                            <div class="col-auto pl-0">
                                <div class="bg-general cr-pointer text-white br-4 shadow px-3 d-middle-center f-14" style="height:32px;" @click="guardar">
                                  <span v-loading="loading">Guardar</span>
                                </div>
                            </div>
                            <!-- <div v-if="admin.id" class="col tres-puntos ml-auto px-0">
                                <div class="bg-f5 cr-pointer text-86 tres-puntos border br-4 px-5 d-middle-center f-14" style="height:32px;" @click="enviarAcceso">
                                    Enviar acceso
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div v-if="admin.id" class="row mx-0 mt-3 f-1+4">
                        <div class="d-flex" style="width:450px;">
													<div class="col-auto px-0 text-86 f-500">
															Última actualización:
													</div>
													<div class="col-auto text-86 ">
														{{ fecha(admin.updated_at) }}
													</div>
                        </div>
                    </div>
                    <!-- <div v-if="admin.id" class="row mx-0 mt-2 f-14">
                        <div class="d-flex" style="width:450px;">
													<div class="col-auto px-0 text-86 f-500">
															Último envio de acceso:
													</div>
													<div class="col-auto text-86 ">
														{{admin.updated_at}}
													</div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-auto" />
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminarAdministrador" titulo="Eliminar administrador" mensaje="¿Desea eliminar este administrador?" sub-mensaje="No podrá acceder nuevamente" @eliminar="eliminarAdmin"/>
        <modal ref="modalEnviarAcceso" titulo="Enviar acceso" adicional="Enviar" no-aceptar @adicional="resetearContrasena">
			<div class="row mx-0 text-center">
				<div class="col-12 text-general mt-1 f-14 f-500">
					¿Desea enviar acceso a este administrador?
				</div>
				<div class="col-12 text-general mt-3 mb-3 f-14">
					Le llegará un mensaje de correo al administrador para poder acceder a la aplicación, tendrá que crear una contraseña
				</div>
			</div>
        </modal>
    </section>
</template>

<script>
import moment from 'moment'
import Admins from '~/services/admins'
import { mapGetters } from 'vuex'
export default {
    data(){
			return {
				repetir: "",
				nombre: '',
				correo_electronico: '',
				numero_celular: '',
				// editar: false,
				admin:{
					id:null,
					nombre:'',
					apellido:'',
					telefono:'',
					correo:'',
					contrasena:'',
					created_at:'',
					updated_at:'',
					responsable:0
				},
				loading:false
			}
    },
	computed:{
		...mapGetters({
			conjunto: 'conjuntos/conjunto',
		}),
	},
	watch:{
		'$route.params.admin': {
        handler: function(admin) {
					if(admin){
						this.obtenerAdmin(admin.id)
					}
        },
        deep: true,
        immediate: true
      }
	},
    methods: {
			eliminarAdministrador(){
				this.$refs.modalEliminarAdministrador.toggle();
			},
			enviarAcceso(){
				this.$refs.modalEnviarAcceso.toggle();
			},
			async obtenerAdmin(admin){
				try{
					this.loading = true
					const params = {
						base_datos : this.conjunto.base_datos,
						id_admin : admin
					}
					const { data } = await Admins.obtenerAdmin(params)
					this.admin = {
						id:data.data.id,
						nombre:data.data.nombre,
						apellido:data.data.apellido,
						telefono:data.data.telefono,
						correo:data.data.correo,
						contrasena: "******",
						created_at:data.data.created_at,
						updated_at:data.data.updated_at,
						responsable : data.data.responsable
					}
					this.repetir = "******"
					this.loading = false
				}catch(e){
					this.loading = false
				}
			},
			async guardar(){
				try{

					let valid = await this.$refs.form.validate()
					if(!valid) return this.notificacion('','Campos incompletos','warning')

          			this.loading = true
					const payload = {
						base_datos : this.conjunto.base_datos,
						id_admin : this.admin.id,
						nombre : this.admin.nombre,
						apellido : this.admin.apellido,
						telefono : this.admin.telefono,
						correo : this.admin.correo,
						responsable : this.admin.responsable
					}

					if(! this.admin.id || this.admin.contrasena != "******"){
						payload.contrasena = this.admin.contrasena
					}

					const { data } = await Admins.guardar(payload)

					if(data.success){
						this.$notify({
							title: 'Guardar',
							message: data.mensaje,
							type: 'success'
						});
					}
          			this.loading = false

					this.$router.replace({name:'conjuntos.administradores'})
				}catch(e){
          			this.loading = false
					this.error_catch(e)
				}
			},
			async resetearContrasena(){
				try{
					const payload = {
						base_datos : this.conjunto.base_datos,
						id_admin : this.admin.id,
					}
					this.$refs.modalEnviarAcceso.toggle();

					const { data } = await Admins.resetearContrasena(payload)
					
					if(data.success){
						this.$notify({
							title: 'Enviar Acceso',
							message: data.mensaje,
							type: 'success'
						});
					}
				}catch(e){

				}				
			},
			fecha(fecha){
				return moment(fecha).format('DD MMM YYYY h:m a')
			},
			async eliminarAdmin(){
				try {
					let params = {
                    	base_datos : this.conjunto.base_datos,
						idAdmin: this.admin.id,
					}
					this.loading = true

					await Admins.delete(params)

					this.notificacion('','Administrador eliminado correctamente','success')
					this.$refs.modalEliminarAdministrador.toggle();
					this.loading = false
					this.$router.push({ name: 'conjuntos.administradores' })
				} catch (error) {
                this.error_catch(error)
                this.loading = false
            }
			}

    }
}
</script>
<style lang="scss" scoped>
.bg-alianza{
    background-color: #FFD54F !important;
}
.bg-cliente{
    background-color: #80DCFF !important;
}
</style>