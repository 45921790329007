<template>
	<modal ref="modalCrearLinea" titulo="Nueva linea" :btns="buttons" :btn-aceptar="false" no-cancelar no-aceptar width="30%">
		<div class="mx-4">
			<ValidationObserver ref="form">
				<div class="mb-3">
					<label for="nombre" class="pl-3" >Nombre de la linea</label>
					<ValidationProvider v-slot="{ errors }" name="nombre" rules="required|max:50">
						<el-input v-model="model.nombre" id="nombre" placeholder="Categoría"/>
						<p class="text-danger f-10">{{ errors[0] }}</p>
					</ValidationProvider>
				</div>
				<div class="mb-4">
					<label for="numero" class="pl-3" >Número de teléfono</label>
					<ValidationProvider v-slot="{ errors }" name="numero" rules="required|numeric|max:15">
						<el-input v-model="model.telefono" id="numero" placeholder="Número"/>
						<p class="text-danger f-10">{{ errors[0] }}</p>
					</ValidationProvider>
				</div>
			</ValidationObserver>
		</div>
	</modal>
</template>


<script>
import { GuardarLineaAtencion } from '~/services/lineas/lineas'
export default {
	data(){
		return {
			buttons: [
				{
					text: 'Cerrar',
					className: 'bg-gris2 border text-muted f-14 px-4',
					action: this.cancelar,
				},
				{
					text: 'Crear',
					className: 'bg-general text-white f-14 px-4',
					action: this.crearLinea
				}
			],
			cancelled: false,
			model: {
				nombre: '',
				telefono: ''
			}
		}
	},
	methods: {
		toggle(){
			if(this.cancelled){
				this.imgErrors = null;
				this.cancelled = false
				this.$refs.form.reset()
			}
			this.$refs.modalCrearLinea.toggle(); 
		},
		limpiar(){
			this.cancelled = true;
			this.model.nombre = '';
			this.model.telefono = '';
			this.$refs.form.reset()
		},
		cancelar(){
			this.cancelled = true;
			this.model.nombre = '';
			this.model.telefono = '';
            this.$refs.modalCrearLinea.toggle();
		},
		async crearLinea(){
			try {
				const valid = await this.$refs.form.validate()
				if(!valid) return;

				const { data } = await GuardarLineaAtencion(this.model.nombre, this.model.telefono);

				this.notificacion('','Categoría creada correctamente','success')
				this.$refs.modalCrearLinea.toggle();
				this.limpiar();

				const linea = {
					id: data.data.id,
					nombre: data.data.nombre,
					telefono: parseInt(data.data.telefono),
					posicion: data.data.posicion,
				}
				this.$emit('creado', linea)
			} catch (error) {
                this.error_catch(error)
			}
		}	
	}
}
</script>