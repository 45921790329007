import axios from 'axios'

const API = '/manager/configuracion'
const APIFAQS = "faqs"

const Configuracion = {
  paisesFiltro(params){
    return axios.get(`${API}/paises/filtro`, { params });
  },
  leecherosFiltro(params){
    return axios.get(`/manager/leecheros/remotos`, { params });
  },
  // correspondencias
    getIcono(idIcono){
      return axios(`${API}/icono/${idIcono}`)
    },
    listarIconosCorrespondencias(){
      return axios.get(`${API}/iconos-correspondencias`);
    },
    guardarIconoCorrespondencia(payload){
      return axios.post(`${API}/iconos-correspondencias`, payload);
    },
    eliminarIcono(params){
      return axios.delete(`${API}/iconos-correspondencias/eliminar`, { params });
    },
  // anuncios
    obtenerTipoAnuncio(params){
      return axios.get(`${API}/tipos-carteleras/obtener-tipo`, { params });
    },
    listarTiposAnuncios(){
      return axios.get(`${API}/tipos-carteleras`);
    },
    guardarTipoAnuncio(payload){
      return axios.post(`${API}/tipos-carteleras`, payload);
    },
    eliminarTipoAnuncio(params){
      return axios.delete(`${API}/tipos-carteleras/eliminar`, { params });
    },
    // categorias de tiendas
    listarCategoriasTiendas(){
      return axios.get(`${API}/categorias-tiendas`);
    },
    guardarCategoriasTiendas(data){
      return axios.post(`${API}/categorias-tiendas`, data, { headers: { 'Content-Type': 'multipart/form-data' } });    
    },
    editarCategoriasTiendas(id, data){
      return axios.post(`${API}/categorias-tiendas/${id}`, data, { headers: { 'Content-Type': 'multipart/form-data' }, params: { _method: 'PUT'} });
    },
    eliminarCategoriasTiendas(id){
      return axios.delete(`${API}/categorias-tiendas/${id}`);
    },
    cambiarPosicionCategoriasTiendas(id, posicion){
      return axios.post(`${API}/categorias-tiendas/${id}/cambiar-posicion`, { posicionNueva: posicion }, { params: { _method: 'PUT'} });
    },
    listarTiendasCategoriasTiendas(id){
      return axios.get(`${API}/categorias-tiendas/${id}/tiendas`);
    },
    conjuntosTiendas(params){
      return axios.get(`${API}/categorias-tiendas/conjuntos`, { params });
    },
    // faqs
    getFaqs(params){
      return axios(`${APIFAQS}`,{params})
    },
    guardarFaq(params){
      return axios.post(`${APIFAQS}/guardar`,params)
    },
    editarFaq(params){
      return axios.put(`${APIFAQS}/editar`,params)
    },
    cambiarPosicion(params){
      return axios.put(`${APIFAQS}/cambiar-posicion`,params)
    },
    eliminarFaq(idFaq){
      return axios.delete(`${APIFAQS}/eliminar/${idFaq}`)
    }
}

export default Configuracion
