import axios from "axios";

export function ObtenerLineasAtencion(){
	return axios.get('manager/configuracion/lineas-atencion')
}

export function GuardarLineaAtencion(nombre, telefono){
	const params = {
		nombre: nombre,
		telefono: telefono
	}
	return axios.post('manager/configuracion/lineas-atencion', params)
}

export function ActualizarLineaAtencion(id, nombre, telefono){
	const params = {
		nombre: nombre,
		telefono: telefono
	}
	return axios.put(`manager/configuracion/lineas-atencion/${id}`, params)
}

export function EliminarLineaAtencion(id){
	return axios.delete(`manager/configuracion/lineas-atencion/${id}`)
}

export function ActualizarPosicionLineaAtencion(id, posicion){
	const params = {
		posicionNueva: posicion
	}
	return axios.put(`manager/configuracion/lineas-atencion/${id}/actualizar-posicion`, params)
}

