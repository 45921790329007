<template>
    <section>
        <div class="row mx-0 px-5">
            <div style="width:756px;">
                <div class="row mx-0">
                    <div class="col-auto px-0 text-black f-600 f-20">
                        Tipos de correspondencia   
                    </div>
                    <div class="col-auto px-0 ml-auto">
                        <router-link :to="{name: 'configurar.manager.crear-correspondencia'}">
                            <div class="bg-general text-white br-4 d-middle-center px-4 cr-pointer shadow" style="height:32px;">
                                Crear
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 px-0 text-black f-15 mt-2">
                        Los tipos de correspondencias lo podrán usar todos los administradores, no podrán ser modificador por ellos.
                    </div>
                </div>
                
            </div>
        </div>
        <div class="row mx-0 py-4" />
        <div class="custom-scroll px-5 overflow-auto" style="height:calc(100vh - 279px)">
            <div class="row mx-0 pt-3">
                <div v-for="(icono, i) in iconos" :key="i" class="mr-3 mb-3" style="width:100px;">
                    <div :class="`rounded-circle d-middle-center position-relative`" style="width:100px;height:80px;">
                        <i v-if="inactivarCorr" class="icon-candado f-22 text-general position-absolute text-general" style="top:-12px;right:4px;" />
                        <img :src="icono.icono" width="80" height="80" class="obj-cover rounded-circle border mb-2" />
                    </div>
                    <p class="text-general my-2 f-15 text-center tres-puntos"> {{icono.nombre}} </p>
                    <div class="row mx-0 j-center py-2">
                        <el-popover
                        placement="bottom-start"
                        trigger="hover">
                            <div class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4" style="height:25px;" @click="eliminarAnuncio(icono.id)">
                                Eliminar
                            </div>
                            <div class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4" style="height:25px;" @click="editarIcono(icono.id)">
                                Editar
                            </div>
                            <div slot="reference" class="br-4 border btn-action">
                                <i class="icon-opciones f-15 text-black" />
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>
        <modal-eliminar ref="modalEliminar" titulo="Eliminar tipo de anuncio" mensaje="¿Desea eliminar este tipo de anuncio?" @eliminar="eliminar"/>
        <modal ref="modalInacCorrespondencia" titulo="Inactivar tipo de correspondencia" no-aceptar adicional="Inactivar" @adicional="inactivarCorr = !inactivarCorr, $refs.modalInacCorrespondencia.toggle()">
            <div class="row mx-0">
                <div class="col-12 text-center text-general f-500 py-5">
                    ¿Desea inactivar este tipo de correspondencia?
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import Configuracion from '~/services/configuracion'
export default {
    data(){
        return {            
            iconos : [],
            idEliminar:null,
            inactivarCorr: false
        }
    },
    mounted(){
      this.listar()
    },
    methods: {
        editarIcono(idIcono){
            this.$router.push({name: 'configurar.manager.crear-correspondencia', query: {editar: idIcono} })
        },
        eliminarAnuncio(idEliminar){
            this.idEliminar = idEliminar
            this.$refs.modalEliminar.toggle();
        },
        async listar(){
            try{
            const { data } = await Configuracion.listarIconosCorrespondencias()
            this.iconos = data.data
            }catch(e){

            }				
        },
        async eliminar(){
            try{
                this.loading = true
                const params = {
                    id_icono : this.idEliminar
                }
                const { data } = await Configuracion.eliminarIcono(params)
                this.listar()
                if(data.success){
                    this.$notify({
                        title: 'Eliminar',
                        message: data.mensaje,
                        type: 'success'
                    });
                }
                this.$refs.modalEliminar.toggle();
                this.loading = false
            }catch(e){
                    this.$refs.modalEliminar.toggle();
                    this.$notify({
                        title: 'Eliminar',
                        message: data.mensaje,
                        type: 'warning'
                    });
                this.loading = false
            }		
        },
        inactivar(){
            this.$refs.modalInacCorrespondencia.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
}
.bg-yellow{
    background-color: #FFD54F;
}
.bg-purple{
    background-color: #B2B2FF;
}
.bg-green{
    background-color: #93EDCF;
}
.bg-pink{
    background-color: #FFB2C2;
}
.bg-blue{
    background-color: #80DCFF;
}
.bg-red{
    background-color: #FF9680;
}
.item:hover{
    background-color:#EBECF0a8;
}
.f-50{
    font-size: 50px !important;
}
</style>
