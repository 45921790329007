<template>
	<section class="tiendas-categorias px-5">
		<div class="d-middle justify-content-between mb-3" style="max-width: 770px;">
			<h1 class="text-black f-600 f-20">Categorías de tiendas</h1>
			<button type="button" class="btn-general" @click="abrirModalCrearCategoria">Crear</button>
		</div>
		<p class="text-black f-15 mb-4">Las tiendas asociadas a los conjuntos podrán ser organizados por categorías</p>
		
		<div class="d-flex gap-5">
			<div class="d-flex flex-column">
				<tiendaCategoria
				class="first" 
				:image="firstCategory.logo"
				:title="firstCategory.nombre"
				editable
				@editar="abrirModalEditarCategoria(firstCategory)"
				>
					<el-popover trigger="click" placement="bottom" popper-class="bg-whitesmoke2">
						<p class="gap-1 w-350px text-tema">
							<span class="mr-1">Servicios,</span>
							<span class="f-light" >es la categoría por defecto que se mostrara a todos los residentes en la app móvil y donde van a estar los servicios ofrecidos para cada conjunto.</span>
						</p>
						<template #reference>
							<i class="icon-interrogacion text-black f-20" />
						</template>
					</el-popover>
				</tiendaCategoria>
				<el-divider class="my-0 bg-whitesmoke2"/>
				<draggable :list="tiendasCategorias" handle=".handle" @change="cambiarPosicion">
					<div v-for="(item, index) in tiendasCategorias" class="d-middle gap-2" >
						<i class="icon-dragable text-gris handle" style="cursor: grab" />
						<div class="d-middle flex-column" @click="listarCategoriasTiendas(item.id)">
							<tiendaCategoria
							class="pb-1" 
							:image="item.logo"
							:title="item.nombre"
							editable
							deleteable
							hover
							@editar="abrirModalEditarCategoria(item)"
							@eliminar="abrirModalEliminarCategoria(item.id)"
							>
								<el-tooltip content="Tiendas que usan esta categoría" placement="bottom" effect="light" :show-arrow="false">
									<div class="border br-4 wh-34 d-middle-center">
										<span class="text-general f-15">{{ item.tiendas }}</span>
									</div>
								</el-tooltip>
							</tiendaCategoria>
							<el-divider class="my-0 bg-whitesmoke2"/>
						</div>
					</div>
				</draggable>
			</div>
			<template v-if="categoriaSeleccionada">
				<div class="d-flex flex-column gap-3 mt-3">
					<div class="d-middle-bt color-general w-550px">
						<span class="f-14">Tiendas asociadas</span>
						<i class="icon-cerrar f-12 cr-pointer" @click="limpiarTiendas"/>
					</div>
					<template v-if="tiendas.length">
						<template v-for="item in tiendas">
							<TiendaAsociada
							:tienda="item.nombre"
							:idTienda="item.idl_tienda"
							:idCategoria="item.categoria_id"
							:conjuntos="item.conjuntos"
							:image="item.logo"
							/>
						</template>
					</template>
					<template v-else>
						<p class="text-general f-16">No hay tiendas asociadas a esta categoría</p>
					</template>
				</div>
			</template>
		</div>
		<modal-crear-categoria ref="modalCrearCategoria" @creado="agregarCategoria"/>
		<modal-editar-categoria ref="modalEditarCategoria" @actualizado="actualizarCategoria"/>
		<modal ref="modalEliminarCategoria" :btns="buttons" no-aceptar no-cancelar titulo="Eliminar categoría" >
			<p class="f-medium f-14 text-general text-center mb-3">¿Desea eliminar esta categoría?</p>
		</modal>
		<modal ref="modalEliminarCategoriaConflicto" no-aceptar no-cancelar titulo="Eliminar categoría" >
			<p class="f-light f-14 text-general text-center mb-3">Hay tiendas asociadas a esta categoría y no es posible eliminarla</p>
		</modal>
	</section>
</template>

<script>
import Configuracion from '~/services/configuracion';
import TiendaAsociada from './components/tiendaAsociada.vue';
import tiendaCategoria from './components/tiendaCategoria.vue';
import modalCrearCategoria from './partials/modalCrearCategoria.vue';
import modalEditarCategoria from './partials/modalEditarCategoria.vue';

export default {
	components: {
    tiendaCategoria,
    TiendaAsociada,
    modalCrearCategoria,
    modalEditarCategoria,
},
	data() {
		return {
			tiendas: [],
			tiendasCategorias: [],
			categoriaSeleccionada: null,
			toDelete: null,
			firstCategory: {
				nombre: 'Servicios',
				logo: '',
			},
			buttons: [
				{
					text: 'Cerrar',
					className: 'bg-gris2 border text-muted f-14 px-4',
					action: () => this.$refs.modalEliminarCategoria.toggle(),
				},
				{
					text: 'Eliminar',
					className: 'bg-general text-white f-14 px-4',
					action: this.eliminarCategoria
				}
			],
		}
	},
	mounted(){
		this.listarCategorias()
	},
	methods: {
		abrirModalCrearCategoria(){
			this.$refs.modalCrearCategoria.toggle()
		},
		abrirModalEditarCategoria(categoria){
			this.$refs.modalEditarCategoria.toggle(categoria)
		},
		abrirModalEliminarCategoria(id){
			this.toDelete = id
			this.$refs.modalEliminarCategoria.toggle();
		},
		agregarCategoria(categoria){
			this.tiendasCategorias.push(categoria)
		},
		actualizarCategoria(categoria){
			this.tiendasCategorias = this.tiendasCategorias.map(el => el.id == categoria.id ? categoria : el)
		},
		limpiarTiendas(){
			this.tiendas = []
			this.categoriaSeleccionada = null
		},
		async listarCategorias(){
			try {
				const { data } = await Configuracion.listarCategoriasTiendas();
				this.tiendasCategorias = data.data
			} catch (error) {
				this.error_catch(error)
			}
		},
		async listarCategoriasTiendas(id){
			try {
				const { data } = await Configuracion.listarTiendasCategoriasTiendas(id);
				this.categoriaSeleccionada = id;
				this.tiendas = data.data
			} catch (error) {
				this.error_catch(error)
			}
		},
		async eliminarCategoria(){
			try {
				const { data } = await Configuracion.eliminarCategoriasTiendas(this.toDelete);

				if(data.success){
					this.tiendasCategorias = this.tiendasCategorias.filter(el => el.id != this.toDelete)
					this.$refs.modalEliminarCategoria.toggle()
				}

				if(data.data.conflict){
					this.$refs.modalEliminarCategoria.toggle()
					this.$refs.modalEliminarCategoriaConflicto.toggle()
				}
			} catch (error) {
				this.error_catch(error)
			}
		},
		async cambiarPosicion(e){
			try {
				const { element, newIndex } = e.moved
				const { data } = await Configuracion.cambiarPosicionCategoriasTiendas(element.id, newIndex +1)
			} catch (error) {
				this.error_catch(error)
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.tiendas-categorias{

	.tienda-categoria{
		width: 360px;
		&.first{
			margin-left: 30px;
		}
	}
}
</style>