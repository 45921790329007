<template>
    <section class="px-4 pt-4">
        <div class="row mx-0 mb-5">
            <div class="col-5 text-black f-20 f-600">
                {{ $route.params.editar_anuncio ? 'Editar' : 'Nuevo' }}  tipo de anuncio
            </div>
            <el-popover
            v-if="this.$route.name == 'configurar.manager.editar-tipo-anuncio'"
            placement="bottom-start"
            trigger="hover">
                <div class="row f-14 align-items-center cr-pointer item mx-0 text-black px-2 br-4" style="height:25px;" @click="eliminarAnuncio(tipo.id)">
                    Eliminar
                </div>
                <div slot="reference" class="br-4 border btn-action">
                    <i class="icon-opciones f-15 text-black" />
                </div>
            </el-popover>
        </div>
        <div class="row mx-0 mb-4">
            <div class="col-5">
                <p class="text-general f-12 pl-3">Nombre del tipo</p>
                <el-input class="w-100" size="small" placeholder="Nombre" show-word-limit maxlength="30" v-model="tipo.nombre" />
            </div>
        </div>
        <div class="row mx-0 mb-4 align-items-end">
            <div class="col-2">
                <p class="text-general f-12 pl-3">Días de vigencia</p>
                <el-input-number class="w-100" size="small" type="number" placeholder="Días" maxlength="5" v-model="tipo.vigencia" />
            </div>
            <el-checkbox v-model="tipo.dinero" class="check-dark mb-1" true-label="1" false-label="0" />
            <p class="col-auto text-general f-12 pl-2 mb-2">Tipo de anuncio con valor</p> 
            <!-- <div class="col"> 
            </div> -->
            <!-- <el-switch
              v-model="tipo.dinero"
              class="w-100" size="small"
              active-value="1"
              inactive-value="0">
            </el-switch>            -->
        </div>
        <div class="row mx-0 px-3 mb-3 mt-3">
            <el-upload
            class="upload-anuncios d-flex"
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple
            :limit="3"
            :file-list="fileList">
            <div class="btn-svg f-14">
              Subir SVG
            </div>
          </el-upload>
        </div>
        <div class="row mx-0 mt-4 px-3">
            <div v-for="(icono, i) in iconos" :key="i" :class="`tipo-anuncio ${tipo.icono == icono ? 'active' : ''} rounded-circle cr-pointer p-1 mr-2`" @click="tipo.icono = icono">
                <div class="w-100 h-100 rounded-circle d-middle-center">
                    <i :class="`${icono} f-14 text-general2`" />
                </div>
            </div>            
        </div>
        <div class="row mx-0 mt-4 px-3">
            <div v-for="(color, c) in colores" :key="c" :class="`tipo-anuncio ${tipo.color == color ? 'active' : ''} rounded-circle cr-pointer p-1 mr-2`" @click="tipo.color = color">
                <div class="w-100 h-100 rounded-circle d-middle-center" :style="`background-color:${color}`">
                </div>
            </div>            
        </div>
        <div class="row mx-0 mt-4">
            <div class="col-auto">
                <div class="bg-general d-middle-center px-3 text-white br-4 cr-pointer" style="height:32px" @click="guardar()">
                    Guardar
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminar" titulo="Eliminar tipo de anuncio" mensaje="¿Desea eliminar este tipo de anuncio?" @eliminar="eliminar"/>
    </section>
</template>

<script>
import Configuracion from '~/services/configuracion'
export default {
    data(){
      return {
        fileList: [],
        colores: [
          '#FFD54F',
          '#B2B2FF',
          '#93EDCF',
          '#FFB2C2',
          '#B7ED93',
          '#80DCFF',
          '#FF9680',
          '#FF94F1',
          '#B2D9FF',
          '#FFC992'
        ],
        iconos:[
          'icon-arrows-cw',
          'icon-activo_celular',
          'icon-alerta_2',
          'icon-añadir',
          'icon-añadir_imagen',
          'icon-anuncios',
          'icon-atras',
          'icon-buscar',
          'icon-camara',
          'icon-cambiar',
          'icon-cancelar',
          'icon-cancelar-1',
          'icon-carro',
          'icon-casa_residente',
          'icon-celular',
          'icon-cerrar',
          'icon-cerrar_sesion',
          'icon-chat_mensaje',
          'icon-chevron',
          'icon-comida',
          'icon-configuracion',
          'icon-confirmado',
          'icon-corazon_favorito',
          'icon-correo',
          'icon-cumpleaños',
          'icon-cupon',
          'icon-denegado',
          'icon-documento',
          'icon-editar',
          'icon-elementos_perdidos',
          'icon-eliminar',
          'icon-fecha_calendario_hoy',
          'icon-general',
          'icon-genero',
          'icon-google',
          'icon-inactivar_usuario',
          'icon-informacion',
          'icon-inicio',
          'icon-inmobiliaria',
          'icon-mantenimiento',
          'icon-me_interesa',
          'icon-menu',
          'icon-mercar',
          'icon-no_me_interesa',
          'icon-noticias',
          'icon-nuevo',
          'icon-ojo_abierto',
          'icon-ojo_cerrado',
          'icon-opciones',
          'icon-pagos',
          'icon-pedidos',
          'icon-pendiente',
          'icon-pin',
          'icon-piscina',
          'icon-producto',
          'icon-publicado',
          'icon-radio_button_off',
          'icon-radio_button_on',
          'icon-republicar',
          'icon-reservas',
          'icon-residente',
          'icon-residentes',
          'icon-solicitud',
          'icon-speech-bubble',
          'icon-telefono',
          'icon-tienda',
          'icon-trabajo',
          'icon-usuario_manual',
          'icon-ventas-dinero'
        ],            
        tipo:{
          id: null,
          nombre: '',
          icono: '',
          color: '',
          vigencia: 1,
          dinero: 0
        },
        fileList: []
      }
    },
    watch:{
			'$route.params.id': {
        handler: function(id) {
					if(id){
						this.obtenerTipo(id)
					}          
        },
        deep: true,
        immediate: true
      }
		},
    methods: { 
      eliminarAnuncio(idEliminar){
        this.idEliminar = idEliminar
        this.$refs.modalEliminar.toggle();
      },
      async guardar(){
				try{

          if(!this.tipo.nombre || !this.tipo.vigencia || !this.tipo.color || !this.tipo.icono) return this.notificacion('','Campos incompletos','warning')

					const payload = {
						id_tipo: this.tipo.id,
            nombre: this.tipo.nombre,
            icono: this.tipo.icono,
            color: this.tipo.color,
            vigencia: this.tipo.vigencia,
            dinero: this.tipo.dinero
						
					}
					const { data } = await Configuracion.guardarTipoAnuncio(payload)
					if(data.success){
						this.$notify({
							title: 'Guardar',
							message: data.mensaje,
							type: 'success'
						});
            this.$router.push({name:'configurar.manager.tipo-anuncios'})
					}          
				}catch(e){
          this.error_catch(e)
				}				
			},
      async obtenerTipo(id){
				try{
					this.loading = true
					const params = {
						id_tipo : id
					}
					const { data } = await Configuracion.obtenerTipoAnuncio(params)
					this.tipo={
            id: data.data.id,
            nombre: data.data.nombre,
            icono: data.data.icono,
            color: data.data.color,
            vigencia: data.data.vigencia,
            dinero: data.data.dinero
          }
					this.loading = false
				}catch(e){
					this.loading = false
				}		
			},
      async eliminar(){
				try{
					this.loading = true
					const params = {
						id_tipo : this.idEliminar
					}
					const { data } = await Configuracion.eliminarTipoAnuncio(params)
					this.loading = false
					
          this.notificacion('','Tipo de anuncio eliminado correctamente','success')
          this.$refs.modalEliminar.toggle();
          this.$router.push({name:'configurar.manager.tipo-anuncios'})
				}catch(e){
          this.error_catch(e)
					this.loading = false
				}		
        },
    }
}
</script>
<style lang="scss" scoped>
.tipo-anuncio{
    width: 44px;
    height: 44px;
    &.active{
        border: 2px solid #C0C0C0;
    }
    div i::before{
        display: contents !important;
    }
}
.upload-anuncio{
    display: flex;
    align-items: center;
    .el-upload-list.el-upload-list--text{

        .el-upload-list__item.is-success{
            margin-top: 0px !important;
        }
    }
}
.item:hover{
    background-color:#EBECF0a8;
}

.btn-svg{
  width: 93px;
  height: 32px;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
}
</style>