<template>
    <section>
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        toggle(){
            
        }
    }
}
</script>
