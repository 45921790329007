<template>
    <section class="crear-vivienda custom-scroll overflow-auto" style="height:calc(100vh - 80px);">
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col d-flex">
                <div class="col">
                    <div v-if="!editar" class="row mx-0 mb-4">
                        <div class="col-auto text-black f-20 f-600">
                            Crear Vivienda
                        </div>
                    </div>
                    <div v-else class="row mx-0 mb-4">
                        <div class="col-9 d-flex">
                            <div class="col-8 pl-0 text-black f-20 f-600">
                                Editar vivienda
                            </div>
                            <div class="col-auto ml-auto pr-0">
                                <el-popover
                                placement="bottom-start"
                                trigger="hover">
                                    <div class="row align-items-center cr-pointer mx-0 text-black px-2 br-4" style="background-color:#EBECF0a8;height:25px;">
                                        Eliminar
                                    </div>
                                    <div slot="reference" class="br-4 border" style="height:32px;width:32px;background-color:#FAFBFC;">
                                        <i class="icon-dots-vertical f-20 text-black" />
                                    </div>
                                </el-popover>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mb-4">
                        <div class="col-5">
                            <p class="text-general f-12 pl-3"> Nombre del grupo de viviendas </p>
                            <el-input v-model="nombre" size="small" placeholder="Nombre" class="w-100" />
                        </div>
                        <div class="col-4">
                            <p class="text-general f-12 pl-3"> Abreviatura del grupo </p>
                            <el-input v-model="abreviatura" size="small" placeholder="Abreviatura" class="w-100" />
                        </div>
                    </div>
                    <div class="row mx-0 mb-4">
                        <div class="col-5">
                            <p class="text-general f-12 pl-3">Nombre de la vivienda</p>
                            <el-input v-model="nombre_vivienda" size="small" placeholder="Nombre" class="w-100" />
                        </div>
                        <div class="col-4">
                            <p class="text-general f-12 pl-3">Abreviatura de la vivienda</p>
                            <el-input v-model="abreviatura_vivienda" size="small" placeholder="Abreviatura" class="w-100" />
                        </div>
                    </div>
                    <div class="row mx-0 mb-4">
                        <div class="col-5">
                            <p class="text-general f-12 pl-3">Tipo de vivienda</p>
                            <el-select v-model="value" placeholder="Seleccionar" class="w-100" size="small">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="row mx-0 mb-5">
                        <div class="col-auto">
                            <div class="bg-general cr-pointer text-white br-4 shadow px-3 d-middle-center f-14" style="height:32px;" @click="editar = !editar">
                                Guardar
                            </div>
                        </div>
                    </div>
                    <div v-if="editar" class="row mx-0">
                        <div class="col-auto text-86 f-14">
                            <b class="mr-2">Última actualización:</b> 10 Mayo 2020 - 10:00 p.m.
                        </div>
                    </div>
                </div>
                <div v-if="editar" class="col-4">
                    <div class="row mx-0 mb-4">
                        <div class="col-auto text-black f-20 f-600">
                            Residentes de la vivienda
                        </div>
                    </div>
                    <div v-for="(r, e) in residentes" :key=e class="row align-items-center mx-0 pl-3 mb-2">
                        <div :class="`${r.tipo == 1 ? 'bg-alianza' : 'bg-cliente'}  rounded-circle d-middle-center`" style="width:32px;height:32px;">
                            <i class="icon-home-city-outline f-15 text-black" />
                        </div>
                        <div class="col f-15 text-black f-500 pr-0">
                            {{ r.nombre }}
                        </div>
                        <div class="col-auto text-86 f-12">
                            {{ r.fecha }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            nombre: '',
            abreviatura: '',
            nombre_vivienda: '',
            abreviatura_vivienda: '',
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: '',
            residentes: [
                {
                    tipo: 1,
                    nombre: 'Alianza Inmobilaria',
                    fecha: '10 Jun. 2021'
                },
                    {
                    tipo: 2,
                    nombre: 'Daniela Cardona',
                    fecha: '01 Jun. 2021'
                }
            ],
            editar: false
        }
    }
}
</script>
<style lang="scss" scoped>
.bg-alianza{
    background-color: #FFD54F !important;
}
.bg-cliente{
    background-color: #80DCFF !important;
}
</style>