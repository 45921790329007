import Categorias from '~/services/tiendas';
<template>
	<modal ref="modalEditarLinea" titulo="Editar linea" :btns="buttons" no-cancelar no-aceptar width="30%">
		<div class="mx-4">
			<ValidationObserver ref="form">
				<div class="mb-3">
					<label for="nombre" class="pl-3" >Nombre de la linea</label>
					<ValidationProvider v-slot="{ errors }" name="categoría" rules="required|max:50">
						<el-input v-model="model.nombre" id="nombre" placeholder="Nombre"/>
						<p class="text-danger f-10">{{ errors[0] }}</p>
					</ValidationProvider>
				</div>
				<div class="mb-4">
					<label for="telefono" class="pl-3" >Número de teléfono</label>
					<ValidationProvider v-slot="{ errors }" name="telefono" rules="required|numeric|max:15">
						<el-input v-model="model.telefono" id="telefono" placeholder="Número"/>
						<p class="text-danger f-10">{{ errors[0] }}</p>
					</ValidationProvider>
				</div>
			</ValidationObserver>
		</div>
	</modal>
</template>


<script>
import { ActualizarLineaAtencion } from '~/services/lineas/lineas'
export default {
	data(){
		return {
			buttons: [
				{
					text: 'Cerrar',
					className: 'bg-gris2 border text-muted f-14 px-4',
					action: this.cancelar,
				},
				{
					text: 'Guardar',
					className: 'bg-general text-white f-14 px-4',
					action: this.editarLinea
				}
			],
			model: {
				id: null,
				nombre: '',
				telefono: ''
			}
		}
	},
	methods: {
		toggle(linea){
			this.model.id = linea.id
			this.model.nombre = linea.nombre
			this.model.telefono = linea.telefono
			this.$refs.modalEditarLinea.toggle(); 
		},
		limpiar(){
			this.model.nombre = '';
			this.model.logo = '';
			this.$refs.form.reset()
		},
		cancelar(){
			this.model.nombre = '';
			this.model.logo = '';
            this.$refs.modalEditarLinea.toggle();
		},
		async editarLinea(){
			try {
				const valid = await this.$refs.form.validate()
				if(!valid) return;

				const { data } = await ActualizarLineaAtencion(this.model.id, this.model.nombre, this.model.telefono);

				if(data.success){
					this.notificacion('','Categoría creada correctamente','success')
					this.$refs.modalEditarLinea.toggle();
					this.limpiar();
					const linea = {
						id: data.data.id,
						nombre: data.data.nombre,
						telefono: data.data.telefono,
						posicion: data.data.posicion,
					}
					this.$emit('actualizado', linea)
				}
				
			} catch (error) {
                this.error_catch(error)
			}
		}	
	}
}
</script>