<template>
    <section>
        <div class="row mx-0 px-5">
            <div class="col-auto px-0 text-black f-20 f-600">
                Leecheros asignados
            </div>
            <div class="col-auto ml-auto">
                <router-link :to="{name: 'conjuntos.leecheros-asignados.asignar-leechero'}">
                    <div class="bg-general f-15 text-white br-4 px-4 d-middle-center cr-pointer" style="height:32px;">
                        Asignar leecheros
                    </div>
                </router-link>
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-5 px-5" style="height:calc(100vh - 170px);">
            <div class="row mx-0">
                <router-link v-for="leechero in leecheros" :key="leechero.id" :to="{name: 'conjuntos.leecheros-asignados.asignar-leechero-id', params: { id_leechero: leechero.id, edit: true }}">
                    <div class="border mb-4 mr-4 text-black py-2 px-2 br-4" style="background-color:#FCFCFC;width:457px;">
                        <div class="row mx-0">
                            <img :src="leechero.logo" width="65" height="65" class="obj-cover border br-4" />
                            <div class="col pl-3 pt-1">
                                <p class="text-black f-500">
                                    <i class="icon-tienda f-18 mr-1" />
                                    <span class="f-bold f-16 f-medium">{{leechero.nombre}}</span>
                                    <template v-if="leechero.categoria">
                                        <span class="text-general "> - </span>
                                        <span class="f-light text-general f-14">{{leechero.categoria}}</span>
                                    </template>
                                </p>
                                <p class="text-black mt-1">
                                    <i class="icon-leechero f-18 mr-1" />
                                    {{leechero.descripcion}}
                                </p>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import Leecheros from '~/services/leecheros'
export default {
    data(){
      return {
        loading:false,
        buscar: '',
        leecheros:[]
      }
    },
    computed:{
        idConjunto(){
            return this.$route.params.id
        }
    },
    mounted(){
      this.listar()
    },
    methods:{
        async listar(){
				try{
                    this.loading = true
                let params={
                    idConjunto:this.idConjunto
                }
					const { data } = await Leecheros.listar(params)
					this.leecheros = data.data
                    this.loading = false
				}catch(e){
                    this.loading = false
				}
			},
        }
}
</script>
<style lang="css" scoped>
a{
    text-decoration: none !important;
}
</style>