<template>
    <section class="d-flex">
        <div class="column-fixed pt-3">
            <p class="pl-5 mb-4 f-18 text-general f-500">
                Proyectos
            </p>
            <div class="custom-scroll overflow-auto" style="height:calc(100vh - 165px)">
                <template v-if="conjuntos.length > 0">
                    <div class="row mx-0 align-items-center px-4 mb-3">
                        <el-checkbox v-model="todosProyectos" class="check-dark" @change="seleccionarConjuntos($event)" />
                        <div class="col f-16 text-general">
                            Todos
                        </div>
                    </div>
                    <div v-for="(item, index) in conjuntos" :key="`proyecto-${index}`" class="row mx-0 align-items-center px-4 mb-3">
                        <el-checkbox v-model="item.checked" class="check-dark mr-1" />
                        <img :src="item.logo" class="obj-cover br-4 ml-2" width="44" height="44" />
                        <div class="col pr-0 f-16 text-general f-300">
                            {{ item.nombre }}
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="row mx-0 align-items-center px-4 mb-3">
                        <p>No hay conjuntos disponibles</p>
                    </div>
                </template>
            </div>
            <div class="row mx-0 j-center">
                <button type="button" class="btn-general px-5" @click="getGraficas">
                    Generar Informe
                </button>
            </div>
        </div>
        <div class="col pt-4">
            <div class="custom-scroll overflow-auto" style="height:calc(100vh - 85px)">
                <p class="text-black f-500 f-20 mb-4">
                    Estadísticas
                </p>
                <template v-if="verGraficas">
                    <template v-if="loading">
                        <div class="d-flex mt-5">
                            <div class="col text-center">
                                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                <p>Cargando Estadísticas</p>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <bars-edades :data="graficas.graficaEdades" />
                        <div class="d-flex mt-5">
                            <div class="col">
                                <pie-generos :data="graficas.graficaGeneros" />
                            </div>
                            <div class="col">
                                <pie-vehiculos :data="graficas.graficaVehiculos" />
                            </div>
                        </div>
                        <bars-vehiculos :data="graficas.graficaModelos" class="mt-5" />
                        <div class="d-flex mt-5">
                            <div class="col">
                                <pie-servicios :data="graficas.graficaServicios" />
                            </div>
                            <div class="col">
                                <pie-mascotas :data="graficas.graficaMascotas" :conjuntos="conjuntosSelected" />
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="d-middle-center" style="height:calc(100vh - 140px)">
                        <p>No hay conjunto(s) seleccionados</p>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>
<script>
import Service from '@/services/estadisticas'
import { load } from 'mime';

export default {
    components: {
        barsEdades: () => import("./components/barsEdades.vue"),
        pieGeneros: () => import("./components/pieGeneros.vue"),
        pieVehiculos: () => import("./components/pieVehiculos.vue"),
        barsVehiculos: () => import("./components/barsVehiculos.vue"),
        pieServicios: () => import("./components/pieServicios.vue"),
        pieMascotas: () => import("./components/pieMascotas.vue"),
    },
    data() {
        return {
            loading: true,
            verGraficas: false,
            conjuntos: [],
            conjuntosSelected: [],
            graficas: {
                graficaEdades: [],
                graficaGeneros: [],
                graficaVehiculos: [],
                graficaModelos: [],
                graficaServicios: [],
                graficaMascotas: [],
            },
            todosProyectos: false,
            proyecto: false,
        };
    },

    mounted(){
        this.getConjuntos();
    },

    methods: {
        async getConjuntos(){
            try {
                const {data} = await Service.getConjuntos();
                this.conjuntos = data.conjuntos.map(function(e) {
                    e.checked = false;
                    return e;
                });
            } catch(e){
                this.error_catch(e)
            }
        },

        async getGraficas(){
            try {
                this.conjuntosSelected = _.map(this.conjuntos.filter((item) => item.checked === true), 'id');
                if(this.conjuntosSelected.length < 1){
                    this.notificacion('No es posible', 'Para generar el informe usted debe seleccionar al menos un conjunto', 'warning'); 
                    this.removerData();
                    return;
                }
                this.loading = true;
                const params = {
                    conjuntos: JSON.stringify(this.conjuntosSelected)
                };

                const {data} = await Service.getGraficas(params);
                this.graficas = data;
                setTimeout(() => {
                    this.verGraficas = true;
                },200);
            } catch(e){
                this.error_catch(e)
            } finally {
                setTimeout(() => {
                    this.loading = false;
                },300);
            }
        },

        async seleccionarConjuntos(event){
            let type = false;
            if(event){
                type = true;
            } else {
                this.removerData();
            }
            this.conjuntos.map((e) => {
                e.checked = type;
                return e;
            });
        },

        async removerData(){
            this.verGraficas = false;
            this.graficas.graficaEdades = [];
            this.graficas.graficaGeneros = [];
            this.graficas.graficaVehiculos = [];
            this.graficas.graficaModelos = [];
            this.graficas.graficaServicios = [];
            this.graficas.graficaMascotas = [];
            this.conjuntosSelected = [];
        },
    },
}
</script>
<style lang="scss" scoped>
    .column-fixed{
        width: 307px;
        height: calc(100vh - 55px) ;
        background-color: #FAFBFC;
        box-shadow: 1px 0px 2px #00000029;
    }

    .lds-roller {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-roller div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
    }
    .lds-roller div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #00D9B5;
        margin: -4px 0 0 -4px;
    }
    .lds-roller div:nth-child(1) {
        animation-delay: -0.036s;
    }
    .lds-roller div:nth-child(1):after {
        top: 63px;
        left: 63px;
    }
    .lds-roller div:nth-child(2) {
        animation-delay: -0.072s;
    }
    .lds-roller div:nth-child(2):after {
        top: 68px;
        left: 56px;
    }
    .lds-roller div:nth-child(3) {
        animation-delay: -0.108s;
    }
    .lds-roller div:nth-child(3):after {
        top: 71px;
        left: 48px;
    }
    .lds-roller div:nth-child(4) {
        animation-delay: -0.144s;
    }
    .lds-roller div:nth-child(4):after {
        top: 72px;
        left: 40px;
    }
    .lds-roller div:nth-child(5) {
        animation-delay: -0.18s;
    }
    .lds-roller div:nth-child(5):after {
        top: 71px;
        left: 32px;
    }
    .lds-roller div:nth-child(6) {
        animation-delay: -0.216s;
    }
    .lds-roller div:nth-child(6):after {
        top: 68px;
        left: 24px;
    }
    .lds-roller div:nth-child(7) {
        animation-delay: -0.252s;
    }
    .lds-roller div:nth-child(7):after {
        top: 63px;
        left: 17px;
    }
    .lds-roller div:nth-child(8) {
        animation-delay: -0.288s;
    }
    .lds-roller div:nth-child(8):after {
        top: 56px;
        left: 12px;
    }
    @keyframes lds-roller {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>