<template>
    <section v-loading="loading">
        <div class="row mx-0 px-5">
            <div class="col-auto px-0 text-black f-20 f-600">
                Administradores
            </div>
            <div class="col-3 ml-auto">
                <el-input v-model="search" prefix-icon="icon-buscar f-18" placeholder="Buscar" size="small" class="w-100"/>
            </div>
            <div class="col-auto">
                <router-link :to="{name: 'conjuntos.administradores.crear-administrador'}">
                    <div class="bg-general f-15 text-white br-4 px-4 d-middle-center cr-pointer" style="height:32px;">
                       Crear Administrador
                    </div>
                </router-link>
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-5 px-5" style="height:calc(100vh - 160px);">
            <div class="row mx-0">
                <router-link
                v-for="admin in admins"
                :key="admin.id"
                :to="{name: 'conjuntos.administradores.crear-administrador', params: { admin: admin }}"
                class="card-administradores border text-black py-2 px-3 br-4 mb-4 mr-4"
                >
                    <div class="row mx-0 h-100 align-items-center">
                        <div class="col-12 px-0">
                            <div class="row mx-0 align-items-center mb-2">
                                <i class="icon-solicitud f-15" />
                                <div class="col pl-1 f-16">
                                    {{admin.nombre}}
                                </div>
                                <div v-if="admin.responsable == 1" class="pr-0 f-13 text-primary">
                                    Responsable
                                </div>
                            </div>
                        </div>
                        <div class="col-12 px-0">
                            <div class="row mx-0 align-items-center">
                                <i class="icon-telefono f-15" />
                                <div class="col-5 pl-1 f-16">
                                    {{admin.telefono}}
                                </div>
                                <i v-if="admin.correo" class="icon-correo f-15" />
                                <div class="col pr-0 tres-puntos pl-1 f-16">
                                    {{admin.correo}}
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import Admins from '~/services/admins'
import { mapGetters } from 'vuex'
export default {
	data(){
		return {
      loading:false,
			search: '',
			adminsArray: []
		}
	},
	mounted(){
		if(this.conjunto.base_datos){
			this.listar()
		}
	},
	computed:{
		...mapGetters({
			conjunto: 'conjuntos/conjunto',
		}),
        admins(){
            return this.adminsArray.filter(el => !this.search || !el.nombre || el.nombre.toLowerCase().includes(this.search.toLowerCase())
            || !el.telefono || String(el.telefono).toLowerCase().includes(this.search.toLowerCase())
            || !el.correo || el.correo.toLowerCase().includes(this.search.toLowerCase()))
        }
	},
	watch:{
		conjunto:{
			handler(val){
				this.listar()
			},
			deep: true
		},
    
	},
	methods:{
		async listar(){
      try{
        const params = {
          base_datos : this.conjunto.base_datos
        }
        const { data } = await Admins.listar(params)
        this.adminsArray = data.data
        this.loading=false

      }catch(e){
        this.error_catch(e)
        this.loading=false

      }				
    },
    async filtro(){
			try {
            this.loading=true
        if(this.search == ''){
          this.listar()
          return
        }
        const params = {
          search : this.search,
          base_datos : this.conjunto.base_datos
        }
				const {data} = await Admins.filtro(params)
				this.admins = data.data
                this.loading=false

								
			} catch (e){
                this.error_catch(e)
                this.loading=false

			}
		}
	}
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
    color: #000000;
}

.card-administradores{
    width: 402px;
    height: 82px;
    background-color:#FCFCFC;
    &:hover{
        box-shadow: 0px 3px 6px #00000029;
    }
}

</style>