<template>
    <section class="px-5 custom-scroll overflow-auto" style="height:calc(100vh - 129px)">
        <div class="row mx-0">
            <div class="col-auto px-0 text-black f-20 f-600">
                Nuevo tipo de correspondencia
            </div>
        </div>
        <div class="row mx-0 py-4" />
        <div class="row mx-0 mb-2">
            <div class="col-5 px-0">
              <p class="text-general f-12 pl-3">Nombre del tipo</p>
              <el-input class="w-100" size="small" placeholder="Nombre" v-model="icono.nombre" maxlength="30" show-word-limit/>
            </div>
        </div>
        <div class="row mx-0 mt-4">
          <div class="col-auto px-0">
              <el-upload
                class="upload-anuncio"
                action="#"
                :auto-upload="false"
                :on-change="agregarArchivo"                 
                :show-file-list="false"
                accept="image/*"
                >
                    <div class="bg-f5 text-general d-middle-center cr-pointer px-3 border br-4 f-14" style="height:32px;">
                        Subir Icono        
                    </div>
              </el-upload>
          </div>
        </div>
        <div class="row mx-0 my-3 mt-5">
          <div class="container-color p-1 mr-4 mb-3" :style="`background-image: url(${imagen});background-size:100% 100%`">
          </div>
          <i class="icon icon-eliminar cr-pointer" @click="eliminarImagen"/>
        </div>
        <div class="row mx-0 mt-4">
            <div class="col-auto px-0">
                <div class="bg-general d-middle-center px-3 text-white br-4 cr-pointer f-14" style="height:32px" @click="guardar()">
                  Guardar
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Configuracion from '~/services/configuracion'
export default {
    data(){
      return {
        imagen: '',
        nombre_tipo: '',
        fileList: [],            
        color_active: null,
        icono:{
          id:null,
          nombre:'',
          icono:null,              
        },
        file:{},
        fileList:[]
      }
    },
    created(){
      if(this.$route.query.editar) this.getIcono()
    },
    methods:{
      async getIcono(){
        try {
          const { data } = await Configuracion.getIcono(this.$route.query.editar)
          this.icono = data.data
          this.imagen = data.data.icono
        } catch (error) {
          this.error_catch(error)
        }
      },
      agregarArchivo(file, fileList){
        this.imagen = URL.createObjectURL(file.raw);
        this.file = file.raw
        this.fileList = fileList
      },
      async guardar(){
				try{

          if(!this.imagen || !this.icono.nombre) return this.$notify({title: '',message: 'Campos incompletos',type:'warning'})

          let formData = new FormData();
          if(this.fileList.length && this.imagen) formData.append('icono', this.file )
          formData.append('nombre', this.icono.nombre)
          if(this.icono.id){
            formData.append('id_icono', this.icono.id)
          } 
					const { data } = await Configuracion.guardarIconoCorrespondencia(formData)
					if(data.success){
						this.$notify({
							title: 'Guardar',
							message: data.mensaje,
							type: 'success'
						});
            this.$router.push({name:'configurar.manager.tipo-correspondencia'})
					}          
				}catch(e){
          this.error_catch(e)
				}				
			},
      eliminarImagen(){
        this.file = null
        this.fileList = []
        this.imagen = ''
      }
    }
}
</script>
<style lang="scss" scoped>
.tipo-anuncio{
    width: 90px;
    height: 90px;
    &.active{
        border: 2px solid #C0C0C0;
    }
    div i::before{
        display: contents !important;
    }
}
.upload-anuncio{
    display: flex;
    align-items: center;
    .el-upload-list.el-upload-list--text{

        .el-upload-list__item.is-success{
            margin-top: 0px !important;
        }
    }
}
.container-color{
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid #868686 !important;
  .color{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #FFEAA7;
  }
}
</style>