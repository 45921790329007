<template>
    <section v-loading="loading">
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col">
                <div class="row mx-0">
                    <div style="width:583px;">
                        <div class="row mx-0 mb-5">
                            <div class="col-auto pl-0 text-black f-20 f-600">
                                {{ $route.params.edit ? 'Leechero Asignado' : 'Asignar leechero' }}
                                
                            </div>
                            <div v-if="$route.params.edit" class="col-auto ml-auto pr-0">
                                <el-popover
                                placement="bottom-start"
                                trigger="hover">
                                    <div class="row mx-0 align-items-center cr-pointer text-black px-2 br-4 item" style="height:25px;" @click="modalEliminarLeechero">
                                        Eliminar
                                    </div>
                                    <div slot="reference" class="btn-action">
                                        <i class="icon-opciones f-15 text-black" />
                                    </div>
                                </el-popover>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div style="width:155px;">
                              <!--
                                <p class="f-12 text-general text-center">Imagen</p>
                                <div class="row mx-0 j-center mb-2">
                                    <img 
                                    :src="leechero.logo" 
                                    class="border cr-pointer" 
                                    style="height:155px;width:155px;background:#F8F9FF;border-radius:4px;" 
                                    />
                                </div>
                                <div class="row mx-0 j-center">
                                    <div class="bg-f5 border br-4 w-100 d-middle-center cr-pointer" style="height:32px;" @click="cambiarImagen">
                                        Cambiar Imagen
                                    </div>
                                </div>
                                <p class="text-general text-center f-12 mt-2">
                                    <i>Esta imagen reemplazará la foto real del leechero.</i>
                                </p>-->
                            </div>
                            <div class="col pr-0 pl-5">
                                <ValidationObserver ref="formLeechero">
                                    <p class="text-general f-12 pl-3">Nombre que usará el leechero</p>
                                    <ValidationProvider v-slot="{errors}" rules="required|max:40" name="nombre">
                                        <el-input v-model="leechero.nombre" placeholder="Nombre" class="w-100" show-word-limit maxlength="40" size="small" />
                                        <span class="text-danger f-10">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <p class="text-general f-12 pl-3 mt-2">Descripción</p>
                                    <ValidationProvider v-slot="{errors}" rules="required|max:500" name="descripción">
                                        <el-input v-model="leechero.descripcion" placeholder="Descripción" class="w-100" show-word-limit maxlength="500" size="small" />
                                        <span class="text-danger f-10">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <div class="row mx-0 mt-3">
                                        <img :src="imagen_categoria" alt="img-categoria" class="wh-50 border br-5 mt-2" :class="{'p-12px': !leechero.categoria?.logo}" @error="imagen_categoria = '/img/estados-vacios/categorias_servicios.svg'">
                                        <div class="col pr-0">
                                            <p class="text-general pl-3 f-12">Seleccionar leechero</p>
                                            <select-tiendas-categorias ref="selectTiendasCategorias" @change="changeTiendasCategoria"/>
                                        </div>
                                    </div>
                                    <div class="row mx-0 mt-3">
                                        <img v-if="imagen_lechero" :src="imagen_lechero" alt="img-leechero" class="circle-user rounded-circle border mt-2" @error="imagen_lechero = null">
                                        <div v-else class="circle-user rounded-circle border d-middle-center mt-2">
                                            <i class="icon-leechero f-18 text-gris" />
                                        </div>
                                        <div class="col pr-0">
                                            <p class="text-general pl-3 f-12">Seleccionar leechero</p>
                                            <select-leecheros ref="selectLeecheros" @setLeechero="setLeechero"/>
                                        </div>
                                    </div>
                                </ValidationObserver>
                                <div class="row mx-0 mt-3">
                                    <div class="bg-general px-3 text-white br-4 d-middle-center cr-pointer" style="height:32px;" @click="guardar()">
                                        Guardar
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="$route.params.edit" class="row mx-0 mt-4 f-14">
                            <div class="d-flex">
                                <div class="col-auto px-0 text-86 f-500">
                                    Última actualización:
                                </div>
                                <div class="col-auto text-86 ">
                                    {{ fecha(leechero.update_at) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto" />
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminarLeechero" titulo="Eliminar leechero" mensaje="¿ Desea eliminar este leechero asignado ?" @eliminar="eliminarLeechero"/>
        <modal ref="modalCambiarImagen" titulo="Cambiar Imagen" no-aceptar adicional="Guardar">
          <div class="row mx-0 my-3 j-center">
            <div class="position-relative d-middle-center">
              <div class="position-absolute text-center" style="z-index:2">
                  <img src="/img/estados-vacios/anadir_imagen.svg" width="80" height="80" />
                  <p class="text-center"> Arrastra una imagen <br /> 
                  o da clic aquí</p>
              </div>
              <slim-cropper 
              ref="fotoReclamo1" 
              :options="slimOptions" 
              class="border cr-pointer" 
              style="height:237px;width:237px;background:#F8F9FF;border-radius:4px;" 
              />
            </div>
          </div>
        </modal>
    </section>
</template>

<script>
import moment from 'moment'
import Util from '~/services/util'
import Leecheros from '~/services/leecheros'
export default {
    data(){
        return {
            loading:false,
            slimOptions: {
                ratio: '1:1',
                label:'',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            nombre_leechero: '',
            imagen_lechero: '',
            imagen_categoria: '',
            url_leechero: '/img/estados-vacios/leechero_vacio.svg',
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: '',
            leechero:{
              id:null,
              nombre:'',
              logo:'/img/estados-vacios/leechero_vacio.svg',
              descripcion:'',
              id_conjunto:null,
              idl_tienda:null,
              tienda:{
                genero_nombre:'',
                id:null,
                imagen:'',
                imagen_mini:'',
                nombre:'',
                cedis:''
              },
            },
        }
    },
    mounted(){
      if(this.$route.params.id_leechero){
        this.obtenerLeechero(this.$route.params.id_leechero)
      }
    },
    computed: {
      currentRouteName() {
        return this.$route.name;
      }
    },
    methods: {
      modalEliminarLeechero(){
        this.$refs.modalEliminarLeechero.toggle();
      },
      cambiarImagen(){
        this.$refs.modalCambiarImagen.toggle();
      },
      setLeechero(id_leechero){
        this.leechero.idl_tienda = id_leechero
      },
      changeTiendasCategoria({id, logo}){
        this.leechero.categoria_id = id
        this.imagen_categoria = logo ?? ''
      },
        async obtenerLeechero(id_leechero){
				try{
					this.loading = true
					const params = {
						id_leechero : id_leechero
					}
					const { data } = await Leecheros.obtenerLeechero(params)
                    this.leechero = data.data

                    const { data: leechero } = await Util.leecherosFiltro({
                        search: this.leechero.tienda.nombre,
                        id_conjunto: this.$route.params.id
                    })

                    this.imagen_categoria = this.leechero.categoria.logo
                    this.$refs.selectTiendasCategorias.setId(this.leechero.categoria_id);

                    if(leechero.data.length) {
                        this.imagen_lechero = leechero.logo
                        this.$refs.selectLeecheros.addLeechero(leechero.data[0])
                        this.$refs.selectLeecheros.setIdLeechero(this.leechero.idl_tienda)
                    }
					this.loading = false
				}catch(e){
					this.loading = false
				}		
			},
        async guardar(){
                
            try{
                
                let validate = await this.$refs.formLeechero.validate()
                if(!this.leechero.idl_tienda && !validate) return this.notificacion('','Campos incompletos','warning')

                this.loading=true
					const payload = {
                    id_leechero:this.leechero.id,
                    nombre:this.leechero.nombre,
                    //logo:null,
                    descripcion:this.leechero.descripcion,
                    categoria_id:this.leechero.categoria_id,
                    id_conjunto:this.$route.params.id,
                    idl_tienda:this.leechero.idl_tienda,
                }

                const { data } = await Leecheros.guardar(payload)
                this.loading=false
                if(data.success){
                    this.$notify({
                        title: 'Guardar',
                        message: data.mensaje,
                        type: 'success'
                    })
                this.$router.replace({name: "conjuntos.leecheros-asignados",params:{id:this.$route.params.id}})
                } 
            }catch(e){
                this.error_catch(e)
                this.loading=false
            }				
        },
        async eliminarLeechero(){
            try {

                let params = {
                    id_conjunto_tienda: this.leechero.id
                }

                const { data } = await Leecheros.eliminarLeechero(params)

                this.notificacion('','Leechero eliminado correctamente','success')
                this.$refs.modalEliminarLeechero.toggle();

                this.$router.replace({name: "conjuntos.leecheros-asignados",params:{id:this.$route.params.id}})
            } catch (error) {
                this.error_catch(error)
            }
        },
        fecha(fecha){
            return moment(fecha).format('DD MMM YYYY h:mm a')
        }
    }
}
</script>
<style lang="scss" scoped>
.circle-user{
    width: 48px;
    height: 48px;
    background-color: #FCFCFC;
}
.item:hover{
    background-color:#EBECF0a8;
}

.p-12px{
    padding: 12px;
}
</style>