<template>
    <section class="px-5">
        <p class="text-black f-20 f-600">
            Preguntas frecuentes
        </p>
        <p class="text-black f-15 mt-1">
            Crea grupo de preguntas y preguntas que puedan ayudar a los residentes y administradores a aclarar sus inquietudes sobre como funciona mi conjunto
        </p>
        <ValidationObserver ref="form" tag="div" class="mt-2">
            <p class="row text-general text-left f-12 pl-3">
                Grupo de preguntas
            </p>
            <div class="row mx-0 d-middle justify-center">
                <div class="col-4 pl-0">
                    <ValidationProvider v-slot="{errors}" name="nombre" rules="required|max:80">
                        <el-input v-model="nombre_grupo" class="w-100" size="small" placeholder="Nombre grupo" />
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
                <div class="col-1">
                    <div class="btn-general f-14 px-2" @click="guardarPreguntaFrecuente">
                        Crear
                    </div>
                </div>
            </div>
        </ValidationObserver>
        <div class="pt-4" style="height:calc(100vh - 300px);">
            <draggable :list="preguntas" handle=".handle" @change="cambiarPosicion">
                <div v-for="(pre, index) in preguntas" :key="index" class="list-question mb-4">
                    <div class="row mx-0 align-items-center">
                        <i class="icon-dragable handle mr-2 f-15 text-muted" />
                        <div :class="`col-8 titulo-pregunta${pre.edit? '-edit pr-2' : ''} d-middle`">
                            <div class="col f-17 f-14 px-0 text-black">
                                <el-input v-model="pre.titulo" class="input-pregunta " size="small" :disabled="!pre.edit" />
                            </div>
                            <template v-if="!pre.edit">
                                <i class="icon-editar cr-pointer f-17 text-black ml-auto" @click="pre.edit = !pre.edit" />
                                <i class="icon-eliminar f-17 text-black ml-2 cr-pointer" @click="eliminarGrupoPreguntas(pre.id)" />
                            </template>
                            <template v-else>
                                <div class="btn-general ml-auto" @click="guardarPreguntaPadre(pre)">
                                    Guardar
                                </div>
                            </template>
                        </div>
                        <el-tooltip v-if="!pre.edit" placement="bottom" content="Añadir pregunta" effect="light">
                            <div class="btn-sm-general ml-2" @click="nuevaPregunta(pre)">
                                <i class="icon-añadir f-18" />
                            </div>
                        </el-tooltip>
                    </div>
                    <draggable :list="pre.preguntas" handle=".handle" @change="cambiarPosicionHijas">
                        <div v-for="(hija,indexH) in pre.preguntas" :key="indexH" class="row mx-0 align-items-center mt-3">
                            <div class="col-auto" />
                            <div class="col px-0">
                                <div class="row mx-0 align-items-center">
                                <i class="icon-dragable handle f-15 text-muted" />
                                    <div class="col-8 childs-title bg-whitesmoke2 d-middle cr-pointer pl-2">
                                        <div class="col-auto f-17 f-14 px-2 text-black" @click="mostrar(hija)">
                                            {{ hija.titulo }}
                                        </div>
                                        <i class="icon-editar f-17 text-black ml-auto" @click="modalEditarPregunta(hija)" />
                                        <i class="icon-eliminar f-17 text-black ml-2 cr-pointer" @click="eliminarPreguntasFrecuentes(hija.id)"/>
                                        <i class="text-black f-25" :class="hija.show ? 'icon-chevron' : 'icon-chevron-down'" @click="mostrar(hija)"/>
                                    </div>
                                    <template v-if="hija.show">
                                        <div class="col-8 text-black mt-2 f-15 px-0 pl-4" style="white-space:pre-line;">
                                            {{ hija.texto }}
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </draggable>
                    
                </div>

            </draggable>
        </div>
        <!-- Partials -->
        <modal-nueva-pregunta ref="modalNuevaPregunta" @actualizar="getPreguntasFrecuentes"/>
        <modal-editar-pregunta ref="modalEditarPregunta" @actualizar="getPreguntasFrecuentes"/>
        <modal-eliminar ref="eliminarGrupoPreguntas" titulo="Eliminar grupo de preguntas" mensaje="¿Desea eliminar este grupo? Las preguntas también se borrarán" @eliminar="eliminarPreguntasFrecuentes(idFaqToDelete,1)"/>
    </section>
</template>

<script>
import Configuracion from '~/services/configuracion'
export default {
    components: {
        modalNuevaPregunta: () => import('./partials/modalNuevaPregunta'),
        modalEditarPregunta: () => import('./partials/modalEditarPregunta')
    },
    data(){
        return {
            idFaqToDelete: null,
            preguntas: [],
            nombre_grupo: '',
        }   
    },
    mounted(){
        this.getPreguntasFrecuentes()
    },
    methods: {
        cambiarPosicion(event){
            let nuevaPosicion = event.moved.newIndex + 1
            let viejaPosicion = event.moved.oldIndex + 1
            let params = {
                nueva: nuevaPosicion,
                vieja: viejaPosicion,
                id_faq: event.moved.element.id
            }

            this.cambiarPosicionPregunta(params)
        },
        cambiarPosicionHijas(event){
            let nuevaPosicion = event.moved.newIndex + 1
            let viejaPosicion = event.moved.oldIndex + 1
            let params = {
                nueva: nuevaPosicion,
                vieja: viejaPosicion,
                id_faq: event.moved.element.id
            }

            this.cambiarPosicionPregunta(params)
        },
        mostrar(hija){
            hija.show = !hija.show
        },
        nuevaPregunta(padre){
            this.$refs.modalNuevaPregunta.toggle(padre.id);
        },
        modalEditarPregunta(hija){
            let hijaCloned = _.clone(hija)
            this.$refs.modalEditarPregunta.toggle(hijaCloned)
        },
        guardarPreguntaPadre(pre){
            if(pre.titulo.length>80) return this.notificacion('','Por favor escribe un título inferior a 80 caracteres.')
            pre.edit = !pre.edit
            this.editarPreguntaFrecuente(pre)
        },
        eliminarGrupoPreguntas(idGrupo){
            this.idFaqToDelete = idGrupo
            this.$refs.eliminarGrupoPreguntas.toggle();
        },
        async getPreguntasFrecuentes(){
            try {

                let tipo = 1

                const { data } = await Configuracion.getFaqs({tipo})
                this.preguntas = data.data.map(el=>{
                    el.edit = false
                    el.preguntas.length ? el.preguntas.map(elhijo=> {elhijo.show = false; return elhijo}) : false;
                    return el})
            } catch (error) {
                this.error_catch(error)
            }
        },
        async guardarPreguntaFrecuente(){
            try {

                let validate = await this.$refs.form.validate()
                if(!validate) return this.notificacion('','Escribe un nombre valido')

                let params = {
                    tipo: 1,
                    titulo: this.nombre_grupo,
                }
                const { data } = await Configuracion.guardarFaq(params)
                
                this.notificacion('','Pregunta creada correctamente','success')
                this.getPreguntasFrecuentes()

                this.nombre_grupo = null
                this.$refs.form.reset()
            } catch (error) {
                this.error_catch(error)
            }
        },
        async editarPreguntaFrecuente(pregunta){
            try {
                
                let params = {
                    id_faq: pregunta.id,
                    titulo: pregunta.titulo,
                    respuesta: pregunta.respuesta ?? undefined
                }

                const { data } = await Configuracion.editarFaq(params)

                this.notificacion('','Pregunta editada correctamente','success')
            } catch (error) {
                this.error_catch(error)
            }
        },
        async cambiarPosicionPregunta(params){
            try {

                const {data} = await Configuracion.cambiarPosicion(params)

                this.notificacion('','Posición cambiada correctamente','success')
            } catch (error) {
                this.error_catch(error)
            }
        },
        async eliminarPreguntasFrecuentes(idPregunta = this.idFaqToDelete,origin = 2 ){
            try {
                const { data } = await Configuracion.eliminarFaq(idPregunta)

                this.notificacion('',`${origin == 1? 'Grupo eliminado' : 'Pregunta eliminada'}  correctamente`,'success')
                this.getPreguntasFrecuentes()
            } catch (error) {
                this.error_catch(error)
            }
        }
        
    }
}
</script>
<style lang="scss" scoped>
/* .list-question{

} */
.titulo-pregunta{
    background-color: rgba(64, 158, 255, 0.141);
    height: 40px;
    border-radius: 4px;
    &-edit{
        height: 40px;
        border-radius: 4px;
        background-color: #FFFFFF;
        border: 1px solid #dbdbdb;
    }
}
.childs-title{
    height: 40px;
    border-radius: 4px;
}
</style>