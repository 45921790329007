<template>
	<div class="tienda-categoria d-middle justify-content-between" :class="{ 'hover': hover }">
		<div class="d-middle gap-3">
			<img :src="img" alt="tienda-categoria-img" @error="img = '/img/estados-vacios/categorias_servicios.svg'">
			<span class="text-black f-15">{{ title }}</span>
		</div>
		<div class="d-middle gap-3">
			<slot />
			<el-popover
			class="cr-pointer" 
			placement="bottom"
			trigger="hover">
				<div v-if="editable" class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4 h-25px" @click="$emit('editar')">
					Editar
				</div>
				<div v-if="deleteable" class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4 h-25px" @click="$emit('eliminar')">
					Eliminar
				</div>
				<div slot="reference" class="br-4 border btn-action">
					<i class="icon-opciones f-15 text-black" />
				</div>
			</el-popover>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		image: {
			type: String,
		},
		title: {
			type: String,
			default: ''
		},
		editable: {
			type: Boolean,
			default: false
		},
		deleteable: {
			type: Boolean,
			default: false
		},
		hover: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			img: this.image ?? ''
		}
	},
	watch: {
		image(val){
			this.img = val ?? ''
		}
	}
}
</script>

<style lang="scss" scoped>
.tienda-categoria{
	padding: 5px;
	margin: 15px 0 10px 0;
	border-radius: 4px;
	transition: background .2s ease-in-out;
	&.hover:hover{
		background-color: var(--color-f5);
		cursor: pointer;
	}
	img{
		width: 50px;
		height: 50px;
	}
}
</style>