import axios from 'axios'

const API = '/manager/usuarios/admins'

const Admins = {
    listar(params){
      return axios.get(`${API}`,{params})
    },
    obtenerAdmin(params){
      return axios.get(`${API}/obtener-admin`,{params})
    },
    filtro(params){
      return axios.get(`${API}/filtro`,{params})
    },
    guardar(payload){
      return axios.post(`${API}/guardar`,payload)
    },
    resetearContrasena(payload){
      return axios.post(`${API}/resetear-contrasena`,payload)
    },
    getConjunto(params){
      return axios.get(`${API}/obtener-conjunto`,{params})
    }, 
    delete(params){
      return axios.delete(`${API}/eliminar`,{params})
    }, 

}

export default Admins
