<template>
    <section v-loading="loading">
        <!-- <miga /> -->
        <div class="row mx-0 px-5 align-items-center">
            <div class="col-auto pl-0 text-black f-20 f-600">
                Estructura residencial
            </div>
            <div class="col-auto mx-3 ml-auto">
                <el-dropdown>
                    <button class="el-dropdown-link br-4 h-32px f-14 d-middle-bt w-140px border px-2 drop-plantilla">
                        Plantilla<i class="el-icon-arrow-down el-icon--right"></i>
                    </button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <export-excel
                            :data="examplePlantilla"
                            worksheet="a"
                            name="estructura.xls"
                            >
                            </export-excel>
                        </el-dropdown-item>
                        <el-dropdown-item >
                            <p @click="modalImportar">
                                Importar plantilla
                            </p>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <!-- <div class="col-auto ml-auto px-0">
                <div class="d-flex">
                    <el-tooltip placement="bottom" content="Ver estructura" effect="light">
                        <div :class="`view cr-pointer border br-l-4 ${view == 1 ? 'active ' : ''}`" style="width:32px;height:32px;" @click="view = 1">
                            <i class="icon-pause f-20" />
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Ver lista" effect="light">
                        <div :class="`view cr-pointer border br-r-4 ${view == 2 ? 'active ' : ''}`" style="width:32px;height:32px;" @click="view = 2">
                            <i class="icon-format-list-bulleted-square f-20" />
                        </div>
                    </el-tooltip>
                </div>
            </div> vista no programada inicialmente, se oculta-->
        </div>
        <div v-if="view == 2" class="row mx-0">
            <div class="col-12 px-0">
                <tabla-general 
                :data="dataTable" 
                class-header="text-general f-600 f-16" 
                activarSeleccionMultiple 
                :columnas="dataColumns" 
                :mostrar-buscador="false" 
                alto="calc(100vh - 235px)"
                :usar-paginacion="false" />
            </div>
        </div>
        <div v-else class="row mx-0  mt-3">
            <div class="col-5 bg-fa px-4 d-middle border py-2">
                <p class="col pl-4 text-black ">
                    <b>Grupo de viviendas</b>
                </p>
                <div class="bg-general text-white d-middle-center br-4 cr-pointer" style="width:32px;height:32px;" @click="anadirGrupo()">
                    <i class="icon-añadir f-16" />
                </div>
            </div>
            <div class="col bg-fa px-4 d-middle border py-2">
                <p class="col pl-4 text-black">
                    <b>Viviendas del grupo:</b>
                    {{nombre_grupo}}
                </p>
                <div class="bg-general text-white d-middle-center br-4 cr-pointer" :style="`width:32px;height:32px;${ nombre_grupo ? '' : 'opacity:0.5;pointer-events:none;'}`" @click="anadirVivienda()">
                    <i class="icon-añadir f-16" />
                </div>
            </div>
            <div class="col-12 px-0">
                <div class="row mx-0">
                    <div class="col-5 border-right px-0">
                        <tabla-general
                        class-header="text-general f-600 f-16"
                        :data="grupos"
                        :columnas="leftColumns"
                        :usar-paginacion="false"
                        :mostrar-buscador="false"
                        :cargando="cargandoTabla"
                        alto="calc(100vh - 300px)"
                        >
                            <template slot="adicionales-izquierda">
                                <el-table-column
                                width="45"
                                >
                                    <template slot-scope="scope">
                                        <div class="row mx-0 j-center">
                                            <div :class="`${vivienda_activa == scope.row.id ? 'bg-general' : '' } br-8`" style="width:12px;height:38px;" />
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="Nombre del grupo"
                                width="180"
                                >
                                    <template slot-scope="scope">
                                        <div class="row mx-0 j-center">
                                            <p class="col f-15 cr-pointer" @click="listarViviendasGrupo(scope.row)">
                                                {{ scope.row.nombre+`[${scope.row.abreviatura}]` }}
                                            </p>
                                        </div>
                                    </template>
                                </el-table-column>
                            </template>
                            <template slot="adicionales-derecha">
                                <el-table-column
                                label="Opciones"
                                min-width="70"
                                class="align-center">
                                    <template slot-scope="scope">
                                        <el-popover
                                        placement="bottom-start"
                                        trigger="hover">
                                            <div class="options-item row align-items-center mb-2 cr-pointer mx-0 text-black px-2 br-4" style=";height:25px;" @click="anadirGrupo(scope.row)">
                                                Editar
                                            </div>
                                            <div class="options-item row align-items-center cr-pointer mx-0 text-black px-2 br-4" :style="`height:25px; ${scope.row.viviendas_count ? 'opacity:0.5;pointer-events:none;' : '' }`" @click="modalEliminarGrupo(scope.row.id)">
                                                Eliminar
                                            </div>
                                            <div slot="reference" class="br-4 border d-middle-center ml-2 mr-4" style="height:32px;width:32px;background-color:#FAFBFC;">
                                                <i class="icon-opciones f-15 text-black" />
                                            </div>
                                        </el-popover>
                                    </template>
                                </el-table-column>
                            </template>
                        </tabla-general>
                    </div>
                    <div class="col-7 border-left">
                        <tabla-general
                        class-header="text-general f-600 f-16"
                        :data="viviendas"
                        :columnas="rightColumns"
                        :usar-paginacion="false"
                        :cargando="cargandoVivienda"
                        sinDatos="No se ha encontrado ningun registro"
                        :mostrar-buscador="false"
                        alto="calc(100vh - 300px)"
                        >
                            <template slot="adicionales-izquierda">
                                <el-table-column
                                label="Nombre"
                                min-width="120"
                                prop="nombre"
                                sortable>
                                    <template slot-scope="scope">
                                        <span class="f-15">{{ scope.row.nombre+`[${scope.row.abreviatura}]` }}</span>
                                    </template>
                                </el-table-column>
                            </template>
                            <template slot="adicionales-derecha">
                                <el-table-column
                                label="Opciones"
                                min-width="70">
                                    <template slot-scope="scope">
                                        <el-popover
                                        placement="bottom-start"
                                        trigger="hover">
                                            <div class="options-item row align-items-center mb-2 cr-pointer mx-0 text-black px-2 br-4" style=";height:25px;" @click="anadirVivienda(scope.row)">
                                                Editar
                                            </div>
                                            <div class="options-item row align-items-center cr-pointer mx-0 text-black px-2 br-4" :style="`height:25px;${scope.row.residentes_count ? 'opacity:0.5;pointer-events:none;' : '' }`" @click="modalEliminarVivienda(scope.row.id)">
                                                Eliminar
                                            </div>
                                            <div slot="reference" class="br-4 border d-middle-center ml-2 mr-4" style="height:32px;width:32px;background-color:#FAFBFC;">
                                                <i class="icon-opciones f-15 text-black" />
                                            </div>
                                        </el-popover>
                                    </template>
                                </el-table-column>
                            </template>
                        </tabla-general>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalAgruparViviendas" :titulo="`${editar ? 'Editar grupo de viviendas' : 'Nuevo grupo de viviendas'}`" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="guardarGrupo">
                <ValidationObserver ref="formGrupo">
                    <div class="row mx-0 px-4 pb-4">
                    <div class="col">
                        <ValidationProvider v-slot={errors} rules="required|max:25" name="nombre">
                            <p class="text-general f-14 pl-3">Nombre del grupo de viviendas</p>
                            <el-input v-model="grupo.nombre" maxlength="25" show-word-limit placeholder="Nombre" class="w-100" size="small" />
                            <span class="text-danger f-10">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col-4">
                        <ValidationProvider v-slot={errors} rules="required|max:12" name="abreviatura">
                            <p class="text-general f-14 pl-3">Abreviatura del grupo</p>
                            <el-input v-model="grupo.abreviatura" maxlength="12" show-word-limit placeholder="Abreviatura" class="w-100" size="small" />
                            <span class="text-danger f-10">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    </div>
                </ValidationObserver>
        </modal>
        <!-- Partials -->
        <modal ref="modalViviendas" :titulo="`${editar ? 'Editar' : 'Nueva'} vivienda`" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="guardarVivienda">
                <ValidationObserver ref="formVivienda">
                    <div class="row mx-0 px-4 pb-4">
                        <div class="col">
                            <p class="text-general f-14 pl-3">Nombre de la vivienda</p>
                            <ValidationProvider v-slot="{errors}" rules ="required|max:25" name="nombre">
                                <el-input v-model="vivienda.nombre" show-word-limit maxlength="25" placeholder="Nombre" class="w-100" size="small" />
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-4">
                            <p class="text-general f-14 pl-3">Abreviatura</p>
                            <ValidationProvider v-slot="{errors}" rules ="required|max:12" name="abreviatura">
                                <el-input v-model="vivienda.abreviatura" show-word-limit maxlength="12" placeholder="Abreviatura" class="w-100" size="small" />
                            <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 px-4 pb-4">
                        <div class="col">
                            <p class="text-general f-14 pl-3">Tipo</p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="tipo">
                                <el-select v-model="vivienda.tipo" placeholder="Seleccionar" class="w-100" size="small">
                                    <el-option
                                    v-for="tipo in tipos"
                                    :key="tipo.id"
                                    :label="tipo.nombre"
                                    :value="tipo.id">
                                    </el-option>
                                </el-select>
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </ValidationObserver>
        </modal>
        <modal ref="modalImportarPlantilla" titulo="Importar plantilla" no-aceptar adicional="Cargar" @adicional="cargarExcel">
			<div class="row mx-3 my-4">
				<el-upload
				ref="upload"
				action="#"
				:on-change="handlePreview"
				:on-remove="handleRemove"
				:on-exceed="handleExceed"
				:auto-upload="false"
				:multiple="false"
				:limit="1"
				accept=".xlsx, .xls"
				>
				<el-button class="bg-general text-white br-6"><i class="icon-arrow-up el-icon--right f-15"></i>Seleccionar archivo</el-button>
				<div slot="tip" class="el-upload__tip f-15">Solo se aceptan archivos Excel, extención (.xlsx)</div>
				</el-upload>
			</div>
		</modal>
        <modal-eliminar
        ref="modalEliminarGrupo"
        titulo="Eliminar grupo de viviendas"
        mensaje="¿Desea eliminar este grupo?"
        @eliminar="eliminarGrupo"
        />
        <modal-eliminar
        ref="modalEliminarVivienda"
        titulo="Eliminar vivienda"
        mensaje="¿Desea eliminar esta vivienda?"
        @eliminar="eliminarVivienda"
        />
    </section>
</template>

<script>
import Vue from 'vue'
import excel from 'vue-excel-export'
import Viviendas from '~/services/viviendas'
import { mapGetters } from 'vuex'

Vue.use(excel)
export default {
    data(){
        return{
            loading: false,
            buscar: '',
            view: 1,
            nombre_grupo: '',
            abreviatura_grupo: '',
            estado_vivienda: 1,
            vivienda_activa: null,
            abreviatura_grupo: '',
            nombre_vivienda: '',
            dataTable: [
                {
                    grupo_viviendas: 'Torre 1 [T1]',
                    vivienda: 'Apartamento 101[Apto 101]',
                    tipo: 'Pequeña',
                    residentes: 2
                }
            ],
            dataColumns: [
                { valor: 'grupo_viviendas', titulo: 'Grupo de viviendas', class: "f-15" },
                { valor: 'vivienda', titulo: 'Vivienda', class: "f-15" },
                { valor: 'tipo', titulo: 'Tipo', class: "f-15" },
                { valor: 'residentes', titulo: 'Residentes', class: "f-15" }
            ],
            leftColumns: [
                { valor: 'viviendas_count', titulo: 'Viviendas', class: "f-15 text-center", ancho: 100},
            ],
            rightColumns: [
                { valor: 'vivienda_tipo', titulo: 'Tipo', class: "f-15", ancho: 180 },
                { valor: 'residentes_count', titulo: 'Residentes', class: "f-15 text-center", ancho: 130 },
            ],
            grupos:[],
            cargandoTabla: false,
            grupo:{
                id:null,
                nombre:'',
                abreviatura:''
            },
            grupo_actual:{
                id:null,
                nombre:'',
                abreviatura:''
            },
            viviendas:[],
            vivienda:{
                id:null,
                nombre:'',
                abreviatura:'',
                tipo:null,
                id_padre:null
            },
            tipos:[],
            editar: true,
            cargandoVivienda: false,
            deleteGrupoId: null,
            deleteViviendaId: null,
            examplePlantilla:[
                {
                    grupo_vivienda:'',
                    grupo_abreviatura:'',
                    vivienda:'',
                    abreviatura:'',
                    tipo:'',
                }
            ],
            file:null,
        }
    },
		mounted(){
			if(this.conjunto.base_datos){
				this.listar()
				this.listarTipos()
			}
		},
		computed:{
			...mapGetters({
				conjunto: 'conjuntos/conjunto',
			}),
		},

		watch:{
			conjunto:{
				handler(val){
					this.listar()
					this.listarTipos()
				},
				deep: true
			}
		},
        methods: {
			anadirGrupo(grupo){
                if(_.isEmpty(grupo)){
                    this.editar = false
                    this.grupo = {}
                    this.$refs.modalAgruparViviendas.toggle();
                }else{
                    this.editar = true
                    this.grupo = _.cloneDeep(grupo)
                    this.$refs.modalAgruparViviendas.toggle();
                }
                console.log(this.grupo);
			},
			anadirVivienda(vivienda){
                if(_.isEmpty(vivienda)){
                    this.editar = false
                    this.vivienda = {}
                    this.$refs.modalViviendas.toggle();
                }else{
                    this.editar = true
                    this.vivienda = _.cloneDeep(vivienda)
                    this.$refs.modalViviendas.toggle();
                }
			},
			modalEliminarGrupo(idGrupo){
                this.deleteGrupoId = idGrupo
                this.$refs.modalEliminarGrupo.toggle();
			},
            modalEliminarVivienda(idVivienda){
                this.deleteViviendaId = idVivienda
                this.$refs.modalEliminarVivienda.toggle();
			},
			async listar(){
                    try{
                        this.cargandoTabla = true;
                        const params = {
                            base_datos : this.conjunto.base_datos
                        }
                        const { data } = await Viviendas.listarGrupos(params)
                        this.grupos = data.data
                    }catch(e){
                        this.cargandoTabla = false;
                        this.error_catch(e)
                    }finally{
                        this.cargandoTabla = false
                    }

			},
			async listarTipos(){
				try{
					const params = {
						base_datos : this.conjunto.base_datos
					}
					const { data } = await Viviendas.listarTipos(params)
					this.tipos = data.data
				}catch(e){
                    this.error_catch(e)
				}
			},
			async guardarGrupo(){
				try{

                    let validate = await this.$refs.formGrupo.validate()

                    if(!validate) return this.notificacion('','Campos incompletos','warning')

                    this.loading = true
					const payload = {
						id_vivienda : this.grupo.id,
						nombre: this.grupo.nombre,
						abreviatura: this.grupo.abreviatura,
						base_datos : this.conjunto.base_datos,
					}
					const { data } = await Viviendas.guardarGrupo(payload)
					if(data.success){
						this.$notify({
							title: 'Guardar Grupo',
							message: data.mensaje,
							type: 'success'
						});
						this.limpiar()
						this.listar()
						this.$refs.modalAgruparViviendas.toggle();
                        this.cargandoTabla = true;
					}
                    this.loading = false
                    this.grupo = {}
				}catch(e){
                    this.loading = false
				}
			},
			async guardarVivienda(){
				try{

                    let validate = await this.$refs.formVivienda.validate()
                    if(!validate) return this.notificacion('','Campos incompletos','warning')

                    this.loading = true
					const payload = {
                        id_vivienda: this.vivienda.id,
						id_padre : this.vivienda_activa,
						nombre: this.vivienda.nombre,
						abreviatura: this.vivienda.abreviatura,
						id_vivienda_tipo: this.vivienda.tipo,
						base_datos : this.conjunto.base_datos,
					}

					const { data } = await Viviendas.guardarVivienda(payload)
					if(data.success){
						this.$notify({
							title: 'Guardar Vivienda',
							message: data.mensaje,
							type: 'success'
						});
						this.limpiar()
						this.listarViviendasGrupo(this.grupo_actual)
						this.$refs.modalViviendas.toggle();
					}

                    if (!payload.id_vivienda) {
                        let index = this.grupos.findIndex(el=>el.id==this.grupo_actual.id)
                        this.grupos[index].viviendas_count++
                    }

                    this.loading = false
				}catch(e){
                    this.loading = false
				}
			},
			async listarViviendasGrupo(grupo){
				try{
                    this.cargandoVivienda = true
					this.grupo_actual = grupo
					this.nombre_grupo = grupo.nombre
					this.vivienda_activa = grupo.id
					const params = {
						base_datos : this.conjunto.base_datos,
						id_vivienda : grupo.id
					}
					const { data } = await Viviendas.listarViviendasGrupo(params)
					this.viviendas = data.data
				}catch(e){
                    this.cargandoVivienda = false
                    this.error_catch(e)
				}finally{
                    this.cargandoVivienda = false
                }
			},
            async eliminarGrupo(){
                try {

                    let params = {
                        base_datos: this.conjunto.base_datos,
                        id_vivienda: this.deleteGrupoId
                    }

                    const { data } = await Viviendas.eliminarGrupo(params)
                    this.deleteGrupoId = null

                    this.notificacion('','Grupo eliminado correctamente','success')
                    this.listar()

                    this.$refs.modalEliminarGrupo.toggle();
                } catch (error) {
                    this.deleteGrupoId = null
                    this.error_catch(error)
                }
            },
            async eliminarVivienda(){
                try {

                    let params = {
                        base_datos: this.conjunto.base_datos,
                        id_vivienda: this.deleteViviendaId
                    }

                    const { data } = await Viviendas.eliminarGrupo(params)
                    this.deleteViviendaId = null

                    this.notificacion('','Vivienda eliminada correctamente','success')
                    this.listarViviendasGrupo(this.grupo_actual)

                    let index = this.grupos.findIndex(el=>el.id==this.grupo_actual.id)
                    this.grupos[index].viviendas_count--

                    this.$refs.modalEliminarVivienda.toggle();
                } catch (error) {
                    this.deleteViviendaId = null
                    this.error_catch(error)
                }
            },
			limpiar(){
				this.grupo = {
					id:null,
					nombre:'',
					abreviatura:''
				},
				this.vivienda = {
					id:null,
					nombre:'',
					abreviatura:'',
					tipo:null,
					id_padre:null
				}
			},
            modalImportar(){
				this.$refs.upload.clearFiles();
				this.$refs.modalImportarPlantilla.toggle();
			},
            handleRemove(file, fileList) {
				this.file = null;
			},
			handlePreview(file) {
				this.file = file.raw;
			},
			handleExceed(files, fileList) {
				this.$message.warning(`Solo se puede seleccionar 1 archivo`);
			},
            async cargarExcel(){
				try {
                    if (this.file == null) return;
					this.loading = true;

					let formData = new FormData();
					formData.append("base_datos", this.conjunto.base_datos)
					formData.append("archivo", this.file)

					const {data} = await Viviendas.importarExcelEstructura(formData);

					this.$refs.modalImportarPlantilla.toggle();
					this.listar();
					this.loading = false;
				}catch(e){
          			this.loading = false
					this.error_catch(e)
				}
			}
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
}
.view{
    background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7);
    color: #000000;
    &.active{
        background-image: inherit !important;
        background-color: #000000 !important;
        color: #FFFFFF !important;
    }
}
.bg-fa{
    background-color: #FAFBFC !important;
}

.options-item{
    &:hover{
        background-color:#EBECF0a8
    }
}
</style>