<template>
    <section class="px-5 custom-scroll overflow-auto" style="height:calc(100vh - 127px)">
        <div class="row mx-0 mt-3">
            <div style="width:137px;">
                <p class="text-center text-general f-12">
                    Imagen
                </p>
                <!-- <div class="col-auto ml-2 px-0 mr-4" @click="cambiarImagen"> -->
                <!-- <slim-cropper
                  ref="cropService"
                  :options="slimOptions"
                  :disabled="disableCropper"
                  class="slim-cropper border cr-pointer obj-cover br-4 my-2 text-center"
                  width="100%" height="137"
                  />
                </div> -->
                <img :src="logo != null ? logo : '/img/logos/logo_login.svg'" width="100%" height="137" class="obj-cover br-4 my-2" />
                <button type="button" class="btn-gris w-100" @click="cambiarImagen">
                    Subir Imagen
                </button>
            </div>
            <div class="col-4 ml-4 pt-4">
              <p class="pl-3 f-12 text-general">
                Nombre
                </p>
                <ValidationObserver ref="createService">
              <ValidationProvider v-slot="{errors}" rules="required|max:40" name="nombre">
                <el-input placeholder="Nombre" v-model="nombre" size="small" class="w-100 mb-auto" maxlength="40" />
                <p class="text-danger f-10">{{ errors[0] }}</p>
              </ValidationProvider>
                <p class="pl-3 f-12 text-general">
                    Número de Whatssap
                </p>
                <ValidationProvider v-slot="{errors}" rules="required|numeric" name="telefono">
                <el-input placeholder="Número" v-model="numeroTelefono" size="small" class="w-100" />
                <p class="text-danger f-10">{{ errors[0] }}</p>
                </ValidationProvider>
            </ValidationObserver>
                <button type="button" class="btn-general mt-3" @click="crearServicio">
                    Guardar
                </button>
            </div>
        </div>
    <!--partials--> 
    <modal-cambiar-logo ref="modalCambiarLogo" @actualizarLogo="getLogo"/>
    </section>
</template>
<script>
import Servicios from '~/services/tiendas'
export default {
  components: {
        modalCambiarLogo: () => import('./partials/modalCambiarLogo'),
    },
    data(){
        return {
            nombre: "",
            numeroTelefono: "",
            buscar: "",
            logo: null,
            disableCropper: false,
            slimOptions: {
              ratio: "1:1",
              label: "Subir imagen",
              buttonCancelLabel: "Cancelar",
              buttonConfirmLabel: "ok",
              buttonUploadLabel: "Ok",
      },
        }
    },
    methods: {
        cambiarImagen(){
          this.$refs.modalCambiarLogo.toggle();
        },
        getLogo(item){
          this.logo = item
        },
        async crearServicio(){
          try {

              let validate = await this.$refs.createService.validate()
                if(!validate) return this.notificacion('','Verifique los datos')

              const payload = {
                nombre: this. nombre,
                telefono: this.numeroTelefono,
                logo: this.logo,
                idCategoria: this.$route.params.idCategoria
              }
              const { data } = await Servicios.crearServicio(payload)
              this.notificacion('Éxito', data.mensaje, "success")
              this.$router.push({name: "tiendas-proveedores.crear", params:{idServicio : data.id}});
          } catch (error) {
              this.error_catch(error)
          }
        }
    }
}
</script>
<style lang="scss" scoped>
.item-popover{
    color: #000000;
    cursor: pointer;
    &:hover{
        background-color: #F4F5F7;
    }
}
.slim-cropper {
  height: 120px;
  width: 120px;
  border-radius: 6px;
  background-color: #F5F5F5;
}
.quill-height{
    height: 135px;
}
/* .container-quill{
    height: 200px;
    overflow-y: auto;
} */
</style>