<template>
    <section class="px-5 custom-scroll overflow-auto" style="height:calc(100vh - 127px)">
        <div class="row mx-0">
            <div class="col-auto px-0 f-500 text-black f-20">
                {{nombreServicio}}
            </div>
            <el-popover ref="popoverOptions" placement="bottom" effect="light" popper-class="br-8 p-2">
                <div class="row mx-0 p-2 f-14 item-popover" @click="eliminarTienda">
                    Eliminar
                </div>
                <button slot="reference" type="button" class="btn-action ml-5">
                    <i class="icon-opciones f-15" />
                </button>
            </el-popover>
        </div>
        <div class="row mx-0 mt-3">
            <div style="width:137px;">
                <p class="text-center text-general f-12">
                    Imagen
                </p>
                  <img :src="logo != null ? logo : detalleServicio.logo" width="100%" height="137" class="obj-cover br-4 my-2" />
                <button type="button" class="btn-gris w-100" @click="cambiarLogo">
                    Subir Imagen
                </button>
            </div>
            <div class="col-4 ml-4 pt-4">
                <p class="pl-3 f-12 text-general">
                    Nombre
                </p>
                <ValidationObserver ref="createService">
                    <ValidationProvider v-slot="{errors}" rules="required|max:40" name="nombre">
                        <el-input placeholder="Nombre" v-model="detalleServicio.nombre" size="small" class="w-100 mb-auto" maxlength="40" />
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                    <p class="pl-3 f-12 text-general">
                        Número de Whatssap
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required|numeric" name="telefono">
                        <el-input placeholder="Número" v-model="detalleServicio.telefono" size="small" class="w-100" />
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </ValidationObserver>
                <div class="py-3 d-middle">
                    <el-checkbox v-model="detalleServicio.visible_visitante" :true-label="1" :false-label="0" class="check-dark" @change="visibleUpdate"/>
                    <p class="pl-3 f-14 text-general"> Visible para los usuarios invitados </p>
                </div>
                <button type="button" class="btn-general mt-3" @click="editarDatosServicio">
                    Guardar
                </button>
            </div>
        </div>
        <div class="row mx-0 py-5" />
        <div class="row mx-0">
            <div class="col-5">
                <p class="text-black f-500 f-20 mb-4">
                    Detalle de los servicios
                </p>
                <el-input type="textarea" v-model="contentQuill" show-word-limit rows="5" maxlength="500" class="w-100" size="small" />

                <div class="justify-content-end">
                <button type="button" class="btn-gris px-4 mt-3 text-right" @click="editarDescripcion">
                        guardar cambios
                </button>
                </div>
                <!-- <div class="container-quill">
                </div> -->
                <div class="row mx-0 mt-5 justify-content-end">
                    <div class="col-12 px-0 text-general f-12">
                        Imagen de portada
                    </div>
                    <div class="w-100 border br-4 bg-gris2" style="height:163px;">
                        <img :src="detalleServicio.banner" class="br-4 text-center" width="100%" height="100%" />
                    </div>
                    <button type="button" class="btn-gris px-4 mt-3" @click="cambiarImagen">
                        Subir Imagen
                    </button>
                </div>
            </div>
            <div class="col-auto px-5" />
            <div class="col-auto px-0">
                <p class="text-black f-500 f-20 mb-3">
                    Cobertura de Conjuntos
                </p>
                <div class="d-middle flex-wrap justify-content-between gap-2 mb-5">
                    <div>
                        <i class="icon-inmobiliaria text-black f-15"></i>
                        <span class="f-light f-14 text-black ml-2">Conjuntos seleccionados: {{ detalleServicio.servicioConjuntos }}</span>
                    </div>
                    <button type="button" class="border br-4 text-f5 text-86 px-2 f-14 py-1" @click="abrirAgregarConjuntos">Seleccionar conjuntos</button>
                </div>
                <!-- <div v-for="(data, index) in conjuntos" :key="index" class="row mx-0 align-items-center mb-4">
                     <el-checkbox v-model="data.conjunto_servicio" class="check-dark" :true-label="1" :false-label="0" @change="modificarDisponibilidadServicioConjunto(index)"/>
                     <div class="col px-2 f-16 f-300 text-general">
                        {{ data.nombre}}
                     </div>
                </div> -->
                <div class="col-auto py-5">
                    <p class="text-black f-500 f-20">
                        Intenciones de compra
                    </p>
                    <p class="text-center f-40 text-86 f-500">
                        {{ detalleServicio.cant_visitas }}
                    </p>
                    <button type="button" class="btn-general px-4 w-100"  >
                        <a href="#verVisitas" class="text-white" > Ver todas </a>
                    </button>
                </div>
            </div>
            <div class="col-auto px-5" />
        </div>
        <div class="row mx-0 py-5" />
        <div class="row mx-0 align-items-center">
            <div class="col-auto px-2 text-black f-500">
                Intenciones de compra
            </div>
            <div class="col-3 ml-auto">
              <small class="text-general f-12 pl-3">Intenciones de compra durante</small>
                <el-select v-model="rango" class="w-100" size="small">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
            <div class="col-3 ml-auto">
              <small class="text-general f-12 pl-3">Intenciones de compra durante</small>
                <el-date-picker
                v-model="fecha"
                class="w-100"
                type="daterange"
                size="small"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="Fecha Inicio"
                end-placeholder="Fecha Fin"
                :disabled="disable_fecha"
                @change="getListaVisitas"/>
            </div>
              <div class="col-2 ml-auto">
                  <el-input v-model="buscar" placeholder="Buscar" class="w-100" size="small" prefix-icon="icon-buscar" />
              </div>
            <div  class="col-12 mt-3" id="verVisitas">
                <el-table :data="visitas.filter(data => !buscar || data.user.nombre.toLowerCase().includes(buscar.toLowerCase()))" style="width: 100%">
                    <el-table-column
                        prop="user.nombre"
                        label="nombre">
                    </el-table-column>
                    <el-table-column
                        prop="nombre"
                        label="Conjunto">
                    </el-table-column>
                    <el-table-column
                        prop="created_at"
                        label="Intención">
                    </el-table-column>
                    <el-table-column
                        prop="user.telefono"
                        label="Celular">
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminarTienda" titulo="Eliminar Tienda" mensaje="¿Desea eliminar esta tienda?" @eliminar="eliminarServicio" />
        <modal-cambiar-imagen ref="modalCambiarImagen" :bannerOriginal="banner" @actualizarBanner="getInfoServicio"/>
        <modal-cambiar-logo ref="modalCambiarLogo" @actualizarLogo="getInfoServicio" :logoOriginal="detalleServicio.logo"/>
		<modal-agregar-conjuntos ref="modalAgregarConjuntos" @checkList="seleccionarConjuntos" @update="conjuntosServicios"/>
    </section>
</template>
<script>
import moment from 'moment'
import Servicios from '~/services/tiendas'
import modalAgregarConjuntos from './partials/modalAgregarConjuntosServicio.vue'
export default {
  components: {
        modalCambiarImagen: () => import('./partials/modalCambiarImagen'),
        modalCambiarLogo: () => import('./partials/modalCambiarLogo'),
		modalAgregarConjuntos
    },
    data(){
        return {
            nombre: "",
            nombreServicio: '',
            numeroTelefono: "",
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            disable_fecha: true,
            rango: 2,
            cobertura: "",
            buscar: "",
            logo: null,
            detalleServicio: {},
            resultadosBuscar: [],
            visitas: [],
            visitasBusqueda: [],
            conjuntos: [],
            idConjuntos: [],
            banner: null,
            idServicio : this.$route.params.idServicio,
            slimOptions: {
              ratio: "1:1",
              label: "Subir imagen",
              buttonCancelLabel: "Cancelar",
              buttonConfirmLabel: "ok",
              buttonUploadLabel: "Ok",
            },
            editorOption: {
                modules: {
                    toolbar: '#toolbar'
                }
            },
            contentQuill: ``,
            options: [{
                value: 1,
                label: 'Hoy'
            }, {
                value: 2,
                label: 'Ultima semana'
            }, {
                value: 3,
                label: 'Ultimo mes'
            }, {
                value: 4,
                label: 'Ultimo 3 meses'
            }, {
                value: 5,
                label: 'Personalizado'
            }],
            value: 0
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.getListaVisitas()
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.getListaVisitas()
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.getListaVisitas()
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(3, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                this.getListaVisitas()
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                this.getListaVisitas()
                break;
            default:
                break;
            }
        },
    },
    mounted(){
      this.getInfoServicio(),
    //   this.getListaConjuntos(),
      this.getListaVisitas()
    },
    methods: {
        cambiarLogo(){
          this.$refs.modalCambiarLogo.toggle();
        },
        cambiarImagen(){
          this.$refs.modalCambiarImagen.toggle();
        },
        eliminarTienda(){
            this.$refs.popoverOptions.doToggle();
            this.$refs.modalEliminarTienda.toggle();
        },
        buscarVisita(){
            this.visitas.forEach((item, index) => {
                let nombre = item.user.nombre.toLowerCase()
                if(nombre.indexOf(this.buscar.toLowerCase()) != -1){
                    if(this.visitasBusqueda.findIndex(i => i.id == item.id) == -1){
                        this.visitasBusqueda.push(this.visitas[index])
                    }
                }else{
                    this.visitasBusqueda.splice(index, 1)
                }
                if(this.buscar.length == 0){
                    this.visitasBusqueda = []
                }
            });
        },
        async getInfoServicio(){
          try {
              const { data } = await Servicios.getDetalleServicio(this.idServicio)
              this.detalleServicio = data.detalleServicio
              this.banner = this.detalleServicio.banner
              this.contentQuill = data.detalleServicio.descripcion
              this.nombreServicio = data.detalleServicio.nombre
          } catch (error) {
              this.error_catch(error)
          }
        },
        async getListaVisitas(){
            try {
                let params = {
                    fechaInicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fechaFin: moment(this.fecha[1]).format('Y-MM-DD'),
                }

                const { data } = await Servicios.getListaVisitasResidentes(this.idServicio, params)
                this.visitas = data.visitas

            } catch (error) {
                return this.error_catch(error)
            }
        },
        // async getListaConjuntos(){
        //   try {
        //     const { data } = await Servicios.getConjuntosConElServicio(this.idServicio)
        //     this.conjuntos = data.conjuntosConCobertura;

        //     this.conjuntos.forEach((item, index) => {
        //         if(item.conjunto_servicio == 1){
        //             this.idConjuntos.push(item.id)
        //         }
        //     });

        //   } catch (error) {
        //       return this.error_catch(error)
        //   }
        // },
        // async modificarDisponibilidadServicioConjunto(item){
        //   try {
        //       this.conjuntos[item].servicio = !this.conjuntos[item].servicio
        //       const { data } = await Servicios.gestionDisponibilidadServicioConjunto(this.idServicio, this.conjuntos[item].id)
        //           this.notificacion('Éxito', data.mensaje, 'success')
        //   } catch (error) {
        //       return this.error_catch(error)
        //   }
        // },
        async eliminarServicio(){
          try {
            const { data } = await Servicios.deleteService(this.idServicio)
            this.notificacion('Éxito', data.mensaje, 'success')
            this.$refs.modalEliminarTienda.toggle();
            this.$router.push({name: 'tiendas-proveedores'});
          } catch (error) {
              this.error_catch(error)
          }
        },
        async editarDatosServicio(){
          try{
            let validate = await this.$refs.createService.validate()
            if(!validate) return this.notificacion('','Verifique los datos')

            const payload = {
              idServicio: this.idServicio,
              nombre : this.detalleServicio.nombre,
              telefono : this.detalleServicio.telefono,
            }
            const { data } = await Servicios.actualizarInfoServicios(payload)
            this.notificacion('Éxito', data.mensaje, 'success')
            this.getInfoServicio()
            }catch (error) {
              this.error_catch(error)
          }
        },
        async editarDescripcion(){
          try{
          const payload = {
              idServicio: this.idServicio,
              campo : 'descripcion',
              valor: this.contentQuill
            }
          const { data } = await Servicios.actualizarInfoEnCaliente(payload)
          this.notificacion('Éxito', data.mensaje, 'success')
          }catch (error) {
              this.error_catch(error)
          }
        },
        async visibleUpdate() {
            try {
                const payload = {
                    idServicio: this.idServicio,
                    visible: this.detalleServicio.visible_visitante,
                }

                const { data } = await Servicios.actualizarVisibilidad(payload);
                this.notificacion('Éxito', data.mensaje, 'success')
            }catch (error) {
              this.error_catch(error)
            }
        },
        abrirAgregarConjuntos(){
			this.$refs.modalAgregarConjuntos.toggle(this.idServicio);
		},
        seleccionarConjuntos(conjuntos){
			this.detalleServicio.servicioConjuntos = conjuntos.length;
			this.$refs.modalAgregarConjuntos.toggle();
		},
        conjuntosServicios(value){
            this.detalleServicio.servicioConjuntos = value;
        }
    }
}
</script>
<style lang="scss" scoped>
.item-popover{
    color: #000000;
    cursor: pointer;
    &:hover{
        background-color: #F4F5F7;
    }
}
.slim-cropper {
  height: 120px;
  width: 120px;
  border-radius: 6px;
  background-color: #F5F5F5;
}
.quill-height{
    height: 135px;
}
/* .container-quill{
    height: 200px;
    overflow-y: auto;
} */
</style>