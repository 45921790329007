<template>
	<section class="lineas-atencion px-5">
		<div class="d-middle justify-content-between mb-3" style="max-width: 770px;">
			<h1 class="text-black f-600 f-20">Lineas de atención</h1>
			<button type="button" class="btn-general" @click="abrirModalCrearLinea">Crear</button>
		</div>
		<div class="d-flex gap-5">
			<draggable :list="lineasAtencion" handle=".handle" @change="cambiarPosicion">
				<div v-for="(item, index) in lineasAtencion" class="d-middle gap-2" >
					<i class="icon-dragable text-gris handle" style="cursor: grab" />
					<div class="d-middle flex-column">
						<lineaAtencion
						class="pb-1" 
						:title="item.nombre"
						:count="item.telefono"
						hover
						@editar="abrirEditarLineaAtencion(item)"
						@eliminar="abrirEliminarLineaAtencion(item.id)"
						/>
						<el-divider class="my-0 bg-whitesmoke2"/>
					</div>
				</div>
			</draggable>
		</div>
		<modal-crear-linea-atencion ref="modalCrearLineaAtencion" @creado="agregarLineaAtencion"/>
		<modal-editar-linea-atencion ref="modalEditarLineaAtencion" @actualizado="actualizarLineaAtencion" />
		<modal ref="modalEliminarLineaAtencion" titulo="Eliminar linea" :btns="buttons" no-aceptar no-cancelar width="30%">
			<p class="f-medium f-14 text-center mb-3">¿Desea eliminar esta línea de atención?</p>
		</modal>
	</section>
</template>

<script>
import lineaAtencion from './components/lineaAtencion.vue';
import modalCrearLineaAtencion from './partials/modalCrearLineaAtencion.vue';
import modalEditarLineaAtencion from './partials/modalEditarLineaAtencion.vue';
import{
	ObtenerLineasAtencion,
	EliminarLineaAtencion,
	ActualizarPosicionLineaAtencion
} from '~/services/lineas/lineas'

export default {
	components: {
		lineaAtencion,
		modalCrearLineaAtencion,
		modalEditarLineaAtencion,
	},
	data(){
		return {
			eliminar: null,
			lineasAtencion: [],
			buttons: [
				{
					text: 'Cerrar',
					className: 'bg-gris2 border text-muted f-14 px-4',
					action: () => this.$refs.modalEliminarLineaAtencion.toggle(),
				},
				{
					text: 'Eliminar',
					className: 'bg-general text-white f-14 px-4',
					action: this.eliminarLineaAtencion
				}
			],
		}
	},
	mounted(){
		this.obtenerLineasAtencion();
	},
	methods: {
		cambiarPosicion(){

		},
		abrirModalCrearLinea(){
			this.$refs.modalCrearLineaAtencion.toggle()
		},
		abrirEditarLineaAtencion(linea){
			this.$refs.modalEditarLineaAtencion.toggle(linea)
		},
		abrirEliminarLineaAtencion(id){
			this.eliminar = id;
			this.$refs.modalEliminarLineaAtencion.toggle()
		},
		agregarLineaAtencion(linea){
			this.lineasAtencion.push(linea)
		},
		actualizarLineaAtencion(linea){
			this.lineasAtencion = this.lineasAtencion.map(el => el.id === linea.id ? linea : el)
		},
		async obtenerLineasAtencion(){
			try {
				const { data } = await ObtenerLineasAtencion();
				if(data.success){
					this.lineasAtencion = data.data
				}
			} catch (error) {
				this.error_catch(error)
			}
		},
		async eliminarLineaAtencion(){
			try {
				const { data } = await EliminarLineaAtencion(this.eliminar);
				if(data.success){
					this.lineasAtencion = this.lineasAtencion.filter(el => el.id != this.eliminar)
					this.$refs.modalEliminarLineaAtencion.toggle();
				}
			} catch (error) {
				this.error_catch(error);
			}
		},
		async cambiarPosicion(e){
			try {
				const { element, newIndex } = e.moved
				await ActualizarPosicionLineaAtencion(element.id, newIndex +1)
			} catch (error) {
				this.error_catch(error)
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.lineas-atencion{
	.linea-atencion{
		width: 400px;
	}
}
</style>