<template>
	<modal ref="modalAgregarConjuntos" titulo="Agregar conjuntos" tamano="modal-md" no-aceptar adicional="Agregar" @adicional="guardarConjuntos">
		<div class="mx-3 px-2 position-sticky border-bottom bg-white z-9 top-0">
			<p class="f-light text-black mb-2">Selecciona los conjuntos que podrán ver el servicio.</p>
			<div>
				<p class="f-light mb-2 f-15">Selecciona los conjuntos por ciudad:</p>
				<el-select v-model="idCiudad" placeholder="Selecciona la ciudad" class="w-100 mb-3" size="small" clearable filterable @change="filtroCiudadConjunto">
					<el-option
						v-for="item in ciudades"
						:key="item.id"
						:label="item.ciudad"
						:value="item.id">
						<span style="float: left">{{ item.ciudad }}</span>
						<span style="float: right; color: #8492a6; font-size: 13px">{{ item.pais }}</span>
					</el-option>
				</el-select>
				<div class="d-middle justify-content-between mb-3">
					<el-checkbox
					v-model="checkAll"
					class="check-dark"
					@change="selectAll"
					>
						<span class="f-16 f-light">Seleccionar todo</span>
					</el-checkbox>
					<span class="text-blue-strong f-light f-14">{{ checkList.length }} conjuntos selecionados</span>
				</div>
			</div>
		</div>
		<div class="mx-3 my-4 px-2 z-0">
			<el-checkbox-group
			v-model="checkList"
			style="display: flex; flex-direction: column"
			>
				<div class="d-flex flex-column gap-3">
					<el-checkbox
					v-for="item, index in conjuntos"
					:key="index"
					:label="item.id"
					class="check-dark"
					>
						<span class="f-16 f-light">{{ item.nombre }}</span>
					</el-checkbox>
					<p v-if="conjuntos.length === 0" class="f-14">No se encontraron coincidencias</p>
				</div>
			</el-checkbox-group>
		</div>
	</modal>
</template>

<script>
import Servicios from '~/services/tiendas'

export default {
	props: {
	},
	data(){
		return {
			search: '',
			checkAll: false,
			checkSelected: false,
			checkList: [],
			filteredCheckboxes: [],
			conjuntos: [],
			ciudades: [],
			idCiudad: null,
			idServicio: null,
		}
	},
	watch: {
	},
	methods: {
		async toggle(idServicio){
			this.idServicio = idServicio;
			this.idCiudad = null;
			await this.getListaConjuntos();
			await this.getCiudades();
			this.$refs.modalAgregarConjuntos.toggle()
		},
		selectAll(value){
			if(value) this.checkList = this.conjuntos.map(el => el.id)
			else this.checkList = []
		},
		async getListaConjuntos(){
			try {
				this.checkList = [];
				const params = {
					idCiudad: this.idCiudad
				}

				const { data } = await Servicios.getConjuntosConElServicio(this.idServicio, params);
				this.conjuntos = data.conjuntosConCobertura;

				this.conjuntos.forEach((item, index) => {
					if(item.conjunto_servicio == 1){
						this.checkList.push(item.id)
					}
				});

			} catch (error) {
				return this.error_catch(error)
			}
        },
		async getCiudades(){
			try {
				const { data } = await Servicios.getConjuntosCiudades();
				this.ciudades = data.data;
			} catch (error) {
				return this.error_catch(error)
			}
        },
		filtroCiudadConjunto(){
			this.getListaConjuntos();
		},
		// async agregarConjuntos(idConjunto){
		// 	try {
		// 		const { data } = await Servicios.gestionDisponibilidadServicioConjunto(this.idServicio, idConjunto);
		// 		this.notificacion('Éxito', data.mensaje, 'success');
		// 	} catch (error) {
		// 		return this.error_catch(error)
		// 	}
		// },
		async guardarConjuntos() {
			try {
				const payload = {
					'idConjuntos' : this.checkList
				};

				const { data } = await Servicios.gestionDisponibilidadServicioConjunto(this.idServicio, payload);
				this.notificacion('Éxito', data.mensaje, 'success');
				this.$refs.modalAgregarConjuntos.toggle();
				this.$emit('update', this.checkList.length);

			} catch (error) {
				return this.error_catch(error)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.z-0{
	z-index: 0;
}
.z-9{
	z-index: 9;
}
.top-0{
	top: 0;
}
.bottom-0{
	bottom: 0;
}
</style>