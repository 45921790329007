<template>
    <section class="px-5">
        <div class="row mx-0">
            <div class="col-auto px-0">
                <p class="text-black f-20 f-600">
                    Versiones de las aplicaciones
                </p>
            </div>
        </div>
		<div class="row mx-0">
			<div class="col-auto px-0 mt-2 mb-4">
                <p class="text-black f-15 mt-1">
                    Escribe el nombre de la versión actual de para cada unas de las aplicaciones de Mi Conjunto en las diferentes tiendas de aplicaciones.
                </p>
            </div>
		</div>
		<div class="row mx-0 mt-4">
            <div class="col-auto px-0">
                <p class="text-black f-18 f-600">
                    ANDROID
                </p>
            </div>
		</div>
		<div class="row mx-0 mt-3">
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 px-3">
                <p class="text-black f-14">
                    Versión residente
                </p>
				<ValidationProvider v-slot="{errors}" rules="required|numericoPersonalizado" name="Residente versión">
					<el-input placeholder="Versión residente" v-model="resiVersionADR" size="small" @blur="saveChange(errors, resiVersionADR, 11)" />
					<span class="text-danger f-10">{{ errors[0] }}</span>
				</ValidationProvider>
            </div>
			<div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 px-3">
                <p class="text-black f-14">
                    Código versión
                </p>
				<ValidationProvider v-slot="{errors}" rules="required|numeric" name="Código versión">
					<el-input placeholder="Código versión" v-model="codVersionResiADR" size="small" @blur="saveChange(errors, codVersionResiADR, 12)" />
					<span class="text-danger f-10">{{ errors[0] }}</span>
				</ValidationProvider>
            </div>
		</div>
		<div class="row mx-0 mt-3">
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 px-3">
                <p class="text-black f-14">
                    Versión administrador
                </p>
				<ValidationProvider v-slot="{errors}" rules="required|numericoPersonalizado" name="Admin versión">
					<el-input placeholder="Admin versión" v-model="admVersionADR" size="small" @blur="saveChange(errors, admVersionADR, 13)" />
					<span class="text-danger f-10">{{ errors[0] }}</span>
				</ValidationProvider>
            </div>
			<div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 px-3">
                <p class="text-black f-14">
                    Código versión
                </p>
				<ValidationProvider v-slot="{errors}" rules="required|numeric" name="Código versión">
					<el-input placeholder="Código versión" v-model="codVersionAdmADR" size="small" @blur="saveChange(errors, codVersionAdmADR, 14)" />
					<span class="text-danger f-10">{{ errors[0] }}</span>
				</ValidationProvider>
            </div>
		</div>
		<div class="row mx-0 mt-5">
            <div class="col-auto px-0">
                <p class="text-black f-18 f-600">
                    iOS
                </p>
            </div>
		</div>
		<div class="row mx-0 mt-3">
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 px-3">
                <p class="text-black f-14">
                    Versión residente
                </p>
				<ValidationProvider v-slot="{errors}" rules="required|numericoPersonalizado" name="Residente versión">
					<el-input placeholder="Versión residente" v-model="resiVersionIOS" size="small" @blur="saveChange(errors, resiVersionIOS, 21)" />
					<span class="text-danger f-10">{{ errors[0] }}</span>
				</ValidationProvider>
            </div>
			<div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 px-3">
                <p class="text-black f-14">
                    Código versión
                </p>
				<ValidationProvider v-slot="{errors}" rules="required|numeric" name="Código versión">
					<el-input placeholder="Código versión" v-model="codVersionResiIOS" size="small" @blur="saveChange(errors, codVersionResiIOS, 22)" />
					<span class="text-danger f-10">{{ errors[0] }}</span>
				</ValidationProvider>
            </div>
		</div>
		<div class="row mx-0 mt-3">
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 px-3">
                <p class="text-black f-14">
                    Versión administrador
                </p>
				<ValidationProvider v-slot="{errors}" rules="required|numericoPersonalizado" name="Admin versión">
					<el-input placeholder="Admin versión" v-model="admVersionIOS" size="small" @blur="saveChange(errors, admVersionIOS, 23)" />
					<span class="text-danger f-10">{{ errors[0] }}</span>
				</ValidationProvider>
            </div>
			<div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 px-3">
                <p class="text-black f-14">
                    Código versión
                </p>
				<ValidationProvider v-slot="{errors}" rules="required|numeric" name="Código versión">
					<el-input placeholder="Código versión" v-model="codVersionAdmIOS" size="small"  @blur="saveChange(errors, codVersionAdmIOS, 24)" />
					<span class="text-danger f-10">{{ errors[0] }}</span>
				</ValidationProvider>
            </div>
		</div>
    </section>
</template>

<script>
import Service from '~/services/configuracion/versiones';
import { extend } from 'vee-validate'

extend('numericoPersonalizado', {
    validate(value){
		let pattern = '[a-zA-Z|°!"#$%&/()=?¿¡]';
        if (value.match(pattern)){
			return false;
		}
		return true;
    },
    message: 'El campo {_field_} no puede contener letras'
});

export default {
    data(){
        return {
			versiones: [],
			resiVersionADR: null,
			codVersionResiADR: null,
			admVersionADR: null,
			codVersionAdmADR: null,
			resiVersionIOS: null,
			codVersionResiIOS: null,
			admVersionIOS: null,
			codVersionAdmIOS: null,
        }   
    },
    mounted(){
        this.getVersiones();
    },
    methods: {
		async getVersiones(){
			try {
				const { data } = await Service.getVersiones();
				data.versiones.map((e) => {
					switch(Number(e.tipo)){
					case 11:
						this.resiVersionADR = e.valor;
						break;
					case 12:
						this.codVersionResiADR = e.valor;
						break;
					case 13:
						this.admVersionADR = e.valor;
						break;
					case 14:
						this.codVersionAdmADR = e.valor;
						break;
					case 21:
						this.resiVersionIOS = e.valor;
						break;
					case 22:
						this.codVersionResiIOS = e.valor;
						break;
					case 23:
						this.admVersionIOS = e.valor;
						break;
					case 24:
						this.codVersionAdmIOS = e.valor;
						break;
					}
				});;
			} catch(e){
				this.error_catch(error);
			}
		},

        async saveChange(errors, value, type){
			try {
				if(errors.length > 0)return;

				const payload = {
					value: value,
					type: type
				};
				const {data} = await Service.postVersion(payload);
				if(data.exito){
					this.notificacion('', data.mensaje, data.tipo);
				}
			} catch(e){
				this.error_catch(e);
				if(e.response.data.data.value){
					this.showErrors(e.response.data.data.value);
				}
			}
		},

		showErrors(errors){
			let contador = errors.length;
			if(contador > 0){
				for(let i = 0; i < contador; i++){
					setTimeout(() => {
						this.notificacion('Alerta', errors[i], 'warning');
					},100);
				}
			}
		},
    }
}
</script>
