<template>
	<div class="linea-atencion d-middle justify-content-between">
		<div class="d-middle gap-3">
			<p class="text-black f-medium f-15">{{ title }}</p>
		</div>
		<div class="d-middle gap-3">
			<p class="f-16 text-86 px-2">{{ count }}</p>
			<el-popover
			class="cr-pointer" 
			placement="bottom"
			trigger="hover">
				<div class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4 h-25px" @click="$emit('editar')">
					Editar
				</div>
				<div class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4 h-25px" @click="$emit('eliminar')">
					Eliminar
				</div>
				<div slot="reference" class="br-4 border btn-action">
					<i class="icon-opciones f-15 text-black" />
				</div>
			</el-popover>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: ''
		},
		count: {
			type: Number,
			default: 0
		}
	}
}
</script>


<style lang="scss" scoped>
.linea-atencion{
	padding: 5px;
	margin: 15px 0 10px 0;
	border-radius: 4px;
}
</style>