<template>
	<div class="tienda-asociada d-flex gap-4 border-bottom br-4">
		<!-- <img :src="img" alt="tienda-asociada-img" @error="img = '/img/estados-vacios/leechero_vacio.svg'"/> -->
		<div class="d-flex flex-column">
			<div class="d-middle gap-2 text-black">
				<i class="icon-tienda f-20" />
				<span class="f-medium f-16">{{ tienda }}</span>
			</div>
			<div class="d-middle gap-2 text-black cr-pointer" @click="modalConjuntos">
				<i class="icon-inmobiliaria f-20" />
				<div class="d-middle gap-2">
					<span class="f-14">Conjuntos({{ conjuntos.length }}):</span>
					<p class="f-light f-14 tres-puntos wM-400px wm-100px">{{ conjuntos.join(', ') }}</p>
					<i class="el-icon-arrow-right f-15" />
				</div>
			</div>
		</div>
		<modal ref="modalAbrirConjuntos" no-aceptar no-cancelar titulo="Conjuntos asociados" >
			<div class="mx-5">
				<div class="d-middle br-15 py-2" style=" box-shadow: 0 2px 5px rgb(0 0 0 / 20%); ">
					<img class="bg-whitesmoke2 br-10 mx-2" :src="img" alt="tienda-asociada-img" @error="img = '/img/estados-vacios/leechero_vacio.svg'"/>
					<div class="d-flex flex-column">
						<div class="d-middle gap-2 text-black">
							<i class="icon-tienda f-20" />
							<span class="f-medium f-16">{{ tienda }}</span>
						</div>
						<div class="d-middle gap-2 text-black cr-pointer">
							<i class="icon-inmobiliaria f-20" />
							<span class="f-14">Conjuntos {{ conjuntos.length }}</span>
						</div>
					</div>
				</div>
				<div class="py-4">
					<div class="d-middle py-1" v-for="(conjunto , key) in conjuntosTienda" :key="key">
						<img class="obj-cover rounded-circle border mx-2" style=" width: 40px; height: 40px; " :src="conjunto.logo"/>
						<p> {{ conjunto.nombre }} </p>
					</div>
				</div>
			</div>
		</modal>
	</div>
</template>
<script>
import Configuracion from '~/services/configuracion';

export default {
	props: {
		image: {
			type: String,
		},
		tienda: {
			type: String,
		},
		conjuntos: {
			type: Array,
		},
		idTienda: {
			type: Number,
		},
		idCategoria: {
			type: Number,
		},
	},
	data() {
		return {
			img: this.image ?? '',
			conjuntosTienda: []
		}
	},
	watch: {
		image(val){
			this.img = val ?? ''
		}
	},
	methods: {
		modalConjuntos(){
			this.conjuntosTienda = [];
			this.conjuntosTiendas();
		},
		async conjuntosTiendas(){
			try {
				let params = {
					idCategoria: this.idCategoria,
					idTienda: this.idTienda
				};

				const { data } = await Configuracion.conjuntosTiendas(params);
				this.conjuntosTienda = data.data;
				this.$refs.modalAbrirConjuntos.toggle()
			} catch (error) {
				this.error_catch(error)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.tienda-asociada{
	width: 550px;
	padding: 9px 10px;
	img{
		width: 65px;
		height: 65px;
	}
}
</style>