import axios from 'axios';

const API = 'manager/configuracion/versiones'

const ENDPOINTS = {
	getVersiones(){
		return axios.get(`${API}/listar`);
	},

	postVersion(payload){
		return axios.post(`${API}/guardar`, payload);
	}
};

export default ENDPOINTS